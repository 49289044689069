import firebase from 'firebase';
const fbdatabase = firebase.database();
/* exporting constants */
export const UPLOAD_SUCCESFULL = 'UPLOAD_SUCCESFULL';
export const UPLOAD_FAILED = 'UPLOAD_FAILED';
export const UPLOADING_MEDIA = 'UPLOADING_MEDIA';
export const ALL_MEDIA = 'ALL_MEDIA';
export const MEDIA_LIKED = 'MEDIA_LIKED';
export const LOADING_MEDIA = 'LOADING_MEDIA'
export const MEDIA_VIWED = 'MEDIA_VIWED';
export const COMMENT_ADDED = 'COMMENT_ADDED'
export const GAME_BUTTON_VALUES = 'GAME_BUTTON_VALUES'
export const USER_REMOVED_FROM_GAME = 'USER_REMOVED_FROM_GAME'
const mediaRef = fbdatabase.ref('media');

export function getButtonState () {
    const gameAction = fbdatabase.ref(`gameAction2`);
    return (dispatch) => {
        gameAction.on("value", function(results) {
            if (!results.val()) {
                return gameAction.set({
                    Up: false,
                    Down: false,
                    Right: false,
                    Left: false,
                    message: 'Welcome to Game Room'
                })
            }else {
                dispatch({
                    type: GAME_BUTTON_VALUES,
                    payload: results.val()
                })
            }
        })
    };
}

export function changeStateOfButton (user, buttonState) {
    const gameAction = fbdatabase.ref(`gameAction2`);
    return (dispatch) => {
        dispatch({
            type: UPLOADING_MEDIA,
            payload: null
        });
        gameAction.set(buttonState).then((results) => {
            dispatch ({
                type: UPLOAD_SUCCESFULL,
                payload: null
            });
        }).catch (err => {
            dispatch ({
                type: UPLOAD_FAILED,
                error: err
            });
        });
    };
};

export function leaveGameRoom (user) {
    //const gameAction = fbdatabase.ref(`gameAction`);
    const gameQueue = fbdatabase.ref(`queue2/${user.uid}`)
    
    return (dispatch) => {
        dispatch({
            type: UPLOADING_MEDIA,
            payload: null
        })
        return Promise.all([
          //  gameAction.remove(),
            gameQueue.remove()
        ]).then(function() {
            dispatch({
                type: USER_REMOVED_FROM_GAME,
                payload: null
            })
        })
    }
}