
import React, { Component } from 'react';
import firebase from 'firebase';
import { Redirect } from 'react-router-dom'
//import { changeStateOfButton, leaveGameRoom,getButtonState } from '../actions/media-action';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { signout } from '../actions/social-signin-action';
import { connect } from 'react-redux';
import {timerr, timerr1, timerstart} from "./game-room"
export const UPLOADING_MEDIA = 'UPLOADING_MEDIA';
export const USER_REMOVED_FROM_GAME = 'USER_REMOVED_FROM_GAME'


const fbdatabase = firebase.database();
const Menu = [
    {route: "/", name: "Home"},
    {route: "/home/game-zone", name: "Game Zone"},
    {route: "/home/recharge", name: "Recharge"},
    {route: "/home/account", name: "Account"},
    {route: "/home/board", name: "Leaderboard"},
    {route: "/home/contact", name: "Contact"},
    // {route: '/home/newpage', name: "New Page"}
];


class SideBar extends Component {
    renderMenu () {
        const menu = Menu.map(menu => {
            return (
                <div key = {menu.name}>
                    <Button  fullWidth = {true} className={this.props.classes.button} key = {menu.name}>
                    <Link to={menu.route} >
                        <div style = {styles.menuColor}> {menu.name}</div>
                    </Link>
                    </Button>  
                </div>
            );
        });
        return menu;
    }

    entergame = () => {
            console.log(this.props.history)
            //return this.props.history.push('/home/game-zone')
            window.location.href = "/home/game/area";
    }
    logout = () => {
        //this.props.leaveGameRoom(this.props.user)
        // /leaveGameRoom(this.props.user);
        const gameQueue = fbdatabase.ref(`queue/${this.props.user.uid}`);
        console.log(gameQueue);
        gameQueue.remove();
        clearTimeout(timerr);
        clearTimeout(timerr1);
        clearTimeout(timerstart);
        console.log(this.props.user);
        this.props.signout();
        window.location.href = "/";
    }
    render () {
        return (
            <div style = {styles.container} >

                <img src = { this.props.user.photoURL } style = {styles.profilepic}/>
                <p>{this.props.user.displayName}</p>
                <div> <p style = {{fontSize: '20px', color: '#3DB4B4', fontWeight: 'bold'}}>Tokens {this.props.user.balance}</p></div>
                <div style = { styles.menu }>
                { this.renderMenu() }
                </div>
                {/* <Button fullWidth = {true} onClick = { this.entergame } style = {styles.logout}>Enter Game</Button> */}
                <Button fullWidth = {true} onClick = { this.logout } style = {styles.logout}>Logout</Button>
            </div>
        );
    };
};

function mapStateToProps({socialSignIn}) {
    return {
        user: socialSignIn
    };
}
function leaveGameRoom (user) {
    //const gameAction = fbdatabase.ref(`gameAction`);
    const gameQueue = fbdatabase.ref(`queue/${user.uid}`)
    console.log(gameQueue);
    return (dispatch) => {
        dispatch({
            type: UPLOADING_MEDIA,
            payload: null
        })
        return Promise.all([
          //  gameAction.remove(),
            gameQueue.remove()
        ]).then(function() {
            dispatch({
                type: USER_REMOVED_FROM_GAME,
                payload: null
            })
        })
    }
}
const styles = {
    container: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '20px'
    },
    menuColor: {
        color: '#3DB4B4',
    },
    menu:{
        marginTop: '20px'
    },
    logout: {
        color: '#3DB4B4'
    },
    profilepic: {
        marginTop: '20px',
        marginBottom: '15px',
        width: '60px',
        height: '60px',
        borderRadius: '50%'
    }
};

export default withStyles (styles)(connect(mapStateToProps, { signout })(SideBar));
