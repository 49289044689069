import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios'
import {updateUserBalance} from '../actions/game-zone-action'
import {NotificationManager} from 'react-notifications';
import firebase from "firebase";
// import './form-css.css';
import logo from '../assets/realclawhome.png';

var userin
var database
var auth = firebase.auth();
var user = firebase.auth().currentUser;
console.log("hereemail")
// console.log(user.email)
class MyVideos extends Component {

    constructor(props) {
        super(props)
        this.state = {
            name: '',
            phone: '',
            address: '',
            newpass: '',
            repeatpass: '',
            curname: '',
            curphone: '',
            curaddress: ''
        }
    }
    reauthenticate = (currentPassword) => {
        var user = firebase.auth().currentUser;
        var cred = firebase.auth.EmailAuthProvider.credential(
            user.email, currentPassword);
        return user.reauthenticateWithCredential(cred);
      }
    componentDidMount(){
        // var curuser = firebase.auth().currentUser;
        userin = firebase.auth().currentUser;
        database = firebase.database();
        var phref = database.ref('users/'+userin.uid+'phone');
        var adref = database.ref('users/'+userin.uid+'address');
        var phh
        var addd
        console.log(userin.displayName)
        phref.once('value', function(snapshot) {
            phh = snapshot.val();
          });
        console.log(phh);
          adref.once('value', function(snapshot) {
            addd = snapshot.val();
          });
        this.setState({
            curname: userin.displayName,
            curphone: phh,
            curaddress: addd
        });
        console.log("print cur name:")
        console.log(this.state.curname)
        console.log(this.state.curaddress)
    }
    handleChange = (event) => {
        this.setState({
            [event.target.name]: [event.target.value][0]
        })

    }
    resetpassword = () => {
        // console.log(this.state.email[0]);
        // console.log(this.state.password[0]);
        var user = firebase.auth().currentUser;
        console.log(user.email);
        auth.sendPasswordResetEmail(user.email).then(function() {
          console.log("good");
          alert("Password Reset Email Sent");
          // Email sent.
        }).catch(function(error) {
          console.log("bad");
          alert("Error with your Email");
          // An error happened.
        });
      }
    rechargeUser = () => {
        var userr = firebase.auth().currentUser;
        var database = firebase.database();

        console.log(userr.displayName);
        // userr.updateProfile({
        //     displayName: "Jane Q. User",
        //     photoURL: "https://example.com/jane-q-user/profile.jpg"
        //   }).then(function() {
        //       console.log('print')
        //     // Update successful.
        //   }).catch(function(error) {
        //     // An error happened.
        //     console.log('failed')
        //   });
        console.log(this.props.user.uid);

        console.log(this.state.name);
                if (this.state.name == ''){
                    console.log('name empty');
                }
                if (this.state.name != ''){
                    console.log('name not empty');
                    database.ref('users/'+ userr.uid).update({
                        displayName: this.state.name
                    });
                }
        console.log(this.state.phone);
                if (this.state.phone == ''){
                    console.log('phone empty');
                }
                if (this.state.phone != ''){
                    console.log('phone not empty');
                    database.ref('users/'+ userr.uid).update({
                        phone: this.state.phone
                    });
                }
        console.log(this.state.address);
                if (this.state.address == ''){
                    console.log('address empty');
                }
                if (this.state.address != ''){
                    console.log('address not empty');
                    database.ref('users/'+ userr.uid).update({
                        address: this.state.address
                    });
                }
        console.log(this.state.newpass);
        console.log(this.state.repeatpass);
                if (this.state.newpass == ''){
                    console.log('newpass empty');
                }
                if (this.state.newpass != ''){
                    console.log("this.state.newpass");
                    this.resetpassword();
                      }
                      
                alert("updated successfully")
                window.location.reload();
    }
    render() {
        return (
            <div style = {styles.mediaObjects}>

            <h3>Welcome to Claw Machine</h3>
            <img src= {logo}></img>

            </div>
        );  
    };
};

function mapStateToProps({ mediaReducer, socialSignIn }) {
    return {
        user: socialSignIn,
        media: mediaReducer.media
    };
}
export default connect(mapStateToProps, {updateUserBalance})(MyVideos);

const styles = {
    mediaObjects: {
        paddingTop: '30px',
        alignItems: 'center',
        display: 'flex',
        flexGrow: '1',
        flexDirection: 'column',
        justifyContent: 'center',
        marginBottom: '210px'
    }  
};