import React, { Component } from "react";
import { connect } from "react-redux";
import { socialSignIn, authStateChanged, signUpWithEmailPassword, signInWithEmailPassword} from "../actions/social-signin-action";
import googleIcon from '../assets/icon-google.png'
import { getUsersList } from '../actions/game-zone-action';
import { getButtonState } from '../actions/media-action';
import './login-css.css';
import firebase from 'firebase';
import Iframe from 'react-iframe'
import Modal from "react-responsive-modal";
import logo from '../assets/realclaw.png';

var auth = firebase.auth();
 let pas1
 let pas2

class Login extends Component {
  
  state = {
    open: false,
    open1: false
  };

  onOpenModal = () => {
    this.setState({ open: true, open1: false });
  };

  onCloseModal = () => {
    this.setState({ open: false, open1: false });
  };

  onOpenModal1 = () => {
    this.setState({ open1: true, open: false });
    console.log("open1 open")
    console.log(this.state.open1)
  };

  onCloseModal1 = () => {
    this.setState({ open1: false, open: false });
    console.log("open1 close")
  };


  

  constructor(props) {
    super(props)
    this.state = {
      email: '',
      password: '',
      chemail: '',
      signupemail: '',
      signuppass: '',
      signuppasscheck: ''
    }
  }
  handleInputChange = (event) => {
    this.setState({
      [event.target.name]: [event.target.value]
    }, function() {
    })
  }
  signUpUser = () => {
    console.log(this.state.signuppass)
    console.log(this.state.signuppasscheck)
    if (this.state.signuppass[0] === this.state.signuppasscheck[0]){
      // alert("password match")
    this.props.signUpWithEmailPassword(this.state.signupemail[0], this.state.signuppass[0])
    }
    if (this.state.signuppass[0] !== this.state.signuppasscheck[0]){
      alert("password didn't match")
    }
    
  }
  signInUser = () => {
    this.props.signInWithEmailPassword(this.state.email[0], this.state.password[0])
  }
  componentDidMount() {
    this.props.getButtonState()
    this.props.getUsersList()
  }
  resetpassword = () => {
    // console.log(this.state.email[0]);
    // console.log(this.state.password[0]);
    console.log(this.state.chemail[0]);
    auth.sendPasswordResetEmail(this.state.chemail[0]).then(function() {
      console.log("good");
      alert("Password Reset Email Sent");
      // Email sent.
    }).catch(function(error) {
      console.log("bad");
      alert("Error with your Email");
      // An error happened.
    });
  }
  socialSigninHandler = event => {
    const signInType = event.target.name;
    switch (signInType) {
      case "facebook":
        this.props.socialSignIn (signInType);
        break;
      case "google":
        this.props.socialSignIn (signInType);
        break;
      default:
        break;
    }
  };

  render() {
    const { open } = this.state;
    return (
      
         <div className = "wrapper" >
  <div className="container">
  <a href="/">
  <img src= {logo}></img>
  </a>
  <form autoComplete="off">
          <h2 style = {{marginTop: 15}}>Forgot Password?</h2>
          <label  for="resetemail">Email:</label>
          <input  type="text" class="testt"  style = {{marginTop: 15}} id="chemail" name="chemail" placeholder="Enter Email" style = {{border: 'solid 1px'}} onChange = {this.handleInputChange}/>
          <input type="button" style = {{marginTop: 15}} name = "Sign In" value = "Reset" onClick = {this.resetpassword}/>
          </form>
  </div>
  </div>

  
    );
  }
};

export default connect (null, { socialSignIn, authStateChanged,getUsersList,getButtonState, signUpWithEmailPassword, signInWithEmailPassword})(Login);
