import firebase from "firebase";

const config = {
  apiKey: "AIzaSyDFBFXXXfh9Mkoi7W46N_sE_bUr8lNtVyQ",
  authDomain: "claw-8d2fd.firebaseapp.com",
  databaseURL: "https://claw-8d2fd.firebaseio.com",
  projectId: "claw-8d2fd",
  storageBucket: "claw-8d2fd.appspot.com",
  messagingSenderId: "17027850199",
  appId: "1:17027850199:web:a74e5845e04e179b"
};


firebase.initializeApp(config);
if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

const authProvider = firebase.auth();
const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();


export {
    firebase,
    authProvider,
    googleAuthProvider,
    facebookAuthProvider
};
