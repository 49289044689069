import * as Authenticator from '../firebase/firebase-signin';
import firebase from "firebase";
import { authProvider } from '../firebase/firebase-config';
// import { findAndCreateUser } from '../firebase/firebase-user';
export const USER_LOGIN_SUCCESSFUL = 'USER_LOGIN_SUCCESSFUL';
export const USER_LOGIN_FAILED = 'USER_LOGIN_FAILED';
export const USER_LOGGED_OUT = 'USER_LOGGED_OUT';
export const SHOW_LOADING_STATE = 'SHOW_LOADING_STATE';
export const INITIALIZATION_SIGN_UP = 'INITIALIZATION_SIGN_UP'
export const SIGNUP_SUCCESSFUL = 'SIGNUP_SUCCESSFUL'
export const ERROR_SIGNUP = 'ERROR_SIGNUP'
const fbdatabase = firebase.database();

export function signout () {
    return (dispatch) => {
        return Authenticator.signout().then(dispatch(signoutSuccessfull())).catch();
    }
}

export function socialSignIn (socialSignInType) {
    switch (socialSignInType) {
        case 'facebook':
            return (dispatch) => {
                return Authenticator.signInWithFaceBook().then ( user => {
                    const userData = {
                        'email': user.email,
                        'photoURL': user.photoURL,
                        'displayName': user.displayName,
                        'uid': user.uid,
                        'balance': 0,
                        'phone': '',
                        'address': '',
                        'prize': 0,
                        'city': '',
                        'zip': '',
                        'state': '',
                        'country': '',
                        'total_played': 0,
                        'total_win': 0,
                        'total_loss': 0,
                        'game1_play': 0,
                        'game1_win': 0,
                        'game1_loss': 0,
                        'game2_play': 0,
                        'game2_win': 0,
                        'game2_loss': 0,
                        'game3_play': 0,
                        'game3_win': 0,
                        'game3_loss': 0,
                    };
                    return findAndCreateUser (userData).then(_user => {
                        
                        dispatch ({
                            type: USER_LOGIN_SUCCESSFUL,
                            payload: _user
                        });
                    })
                }).catch (err => {
                    dispatch (failureResponse (err));
                });
            }
        case 'google':
            return (dispatch) => {
                return Authenticator.signInWithGoogle().then (user => {
                    const userData = {
                        'email': user.email,
                        'photoURL': user.photoURL,
                        'displayName': user.displayName,
                        'uid': user.uid,
                        'balance': 0,
                        'phone': '',
                        'address': '',
                        'prize': 0,
                        'city': '',
                        'zip': '',
                        'state': '',
                        'country': '',
                        'total_played': 0,
                        'total_win': 0,
                        'total_loss': 0,
                        'game1_play': 0,
                        'game1_win': 0,
                        'game1_loss': 0,
                        'game2_play': 0,
                        'game2_win': 0,
                        'game2_loss': 0,
                        'game3_play': 0,
                        'game3_win': 0,
                        'game3_loss': 0,
                    };
                    return findAndCreateUser (userData).then(_user => {
                        
                        dispatch ({
                            type: USER_LOGIN_SUCCESSFUL,
                            payload: _user
                        });
                    })
                }).catch (err => {
                    dispatch (failureResponse (err));
                });
            };
        default:
            break;
    };
};

export function signUpWithEmailPassword (email, password) {
    return (dispatch) => {
        dispatch({
            type: INITIALIZATION_SIGN_UP,
            payload: null
        })
        return Authenticator.signUpWithEmailPassword(email, password).then(user => {
            const userData = {
                'email': user.email,
                'photoURL': user.photoURL,
                'displayName': user.displayName,
                'uid': user.uid,
                'balance': 0,
                'phone': '',
                'address': '',
                'prize': 0,
                'city': '',
                'zip': '',
                'state': '',
                'country': '',
                'total_played': 0,
                        'total_win': 0,
                        'total_loss': 0,
                        'game1_play': 0,
                        'game1_win': 0,
                        'game1_loss': 0,
                        'game2_play': 0,
                        'game2_win': 0,
                        'game2_loss': 0,
                        'game3_play': 0,
                        'game3_win': 0,
                        'game3_loss': 0,
            };
            return findAndCreateUser (userData).then(_user => {
                
                dispatch ({
                    type: USER_LOGIN_SUCCESSFUL,
                    payload: _user
                });
            })
        }).catch (err => {
            dispatch({
                type: ERROR_SIGNUP,
                payload: err
            })
        })
    }
}

export function signInWithEmailPassword (email, password) {
    return (dispatch) => {
        dispatch({
            type: INITIALIZATION_SIGN_UP,
            payload: null
        })
        return Authenticator.signInWithEmailPassword(email, password).then(user => {
            const userData = {
                'email': user.email,
                'photoURL': user.photoURL,
                'displayName': user.displayName,
                'uid': user.uid,
                'balance': 0,
                'phone': '',
                'address': '',
                'prize': 0,
                'city': '',
                'zip': '',
                'state': '',
                'country': '',
                'total_played': 0,
                        'total_win': 0,
                        'total_loss': 0,
                        'game1_play': 0,
                        'game1_win': 0,
                        'game1_loss': 0,
                        'game2_play': 0,
                        'game2_win': 0,
                        'game2_loss': 0,
                        'game3_play': 0,
                        'game3_win': 0,
                        'game3_loss': 0,
            };
            return findAndCreateUser (userData).then(_user => {
                dispatch ({
                    type: USER_LOGIN_SUCCESSFUL,
                    payload: _user
                });
            })
        }).catch (err => {
                        console.log("singinn")

            return (err) => {
            console.log("Incorrect Email or password")
            }
            dispatch({
                type: ERROR_SIGNUP,
                payload: err
            })
        })
    }    
}

export function authStateChanged () {
    return (dispatch) => {
        dispatch({
            type: SHOW_LOADING_STATE,
            payload: true
        });
        return authProvider.onAuthStateChanged(function(response) {
            if(!response || response == null) 
                dispatch ({
                    type: USER_LOGGED_OUT,
                    payload: null
                }); 
            else {
                
                const userData = {
                    'email': response.email,
                    'photoURL': response.photoURL,
                    'displayName': response.displayName,
                    'uid': response.uid,
                    'balance': 0,
                    'phone': '',
                    'address': '',
                    'prize': 0,
                    'city': '',
                    'zip': '',
                    'state': '',
                    'country': '',
                    'total_played': 0,
                        'total_win': 0,
                        'total_loss': 0,
                        'game1_play': 0,
                        'game1_win': 0,
                        'game1_loss': 0,
                        'game2_play': 0,
                        'game2_win': 0,
                        'game2_loss': 0,
                        'game3_play': 0,
                        'game3_win': 0,
                        'game3_loss': 0,
                };
                findAndCreateUser (userData).then(_user => {
                    dispatch({
                        type: USER_LOGIN_SUCCESSFUL,
                        payload: _user
                    });
                })
            }
            // dispatch (authChangeResponse(response))
        })
    }
}
function findAndCreateUser (user) {
    return new Promise ((resolve, reject) => {
        
    const usersRef = fbdatabase.ref(`users/${user.uid}`);
    usersRef.on("value", function(_user) {
        if(_user.val()) {
            return resolve(_user.val())
        }
            
        else {
            
            if(typeof user.email !== 'undefined') {
                usersRef.set(user)
                return resolve(user)
            }
            
        }
    })
    })
    
    
}

function successResponse ({user}) {
    console.log('here is user', user)
    
    debugger
    console.log('user data')
    debugger
    
    
}

function authChangeResponse (user) {
    if(user == null || user === 'User logged out') {
        return {
            type: USER_LOGGED_OUT,
            payload: null
        }
    }
    const userData = {
        'email': user.email,
        'photoURL': user.photoURL,
        'displayName': user.displayName,
        'uid': user.uid,
        balance: 0,
        'phone': '',
        'address': '',
        'prize': 0,
        'city': '',
        'zip': '',
        'state': '',
        'country': '',
        'total_played': 0,
                        'total_win': 0,
                        'total_loss': 0,
                        'game1_play': 0,
                        'game1_win': 0,
                        'game1_loss': 0,
                        'game2_play': 0,
                        'game2_win': 0,
                        'game2_loss': 0,
                        'game3_play': 0,
                        'game3_win': 0,
                        'game3_loss': 0,
    };
    findAndCreateUser (userData).then(_user => {
        return {
            type: USER_LOGIN_SUCCESSFUL,
            payload: _user
        };
    })
    
}

function failureResponse (error) {
    return {
        type: USER_LOGIN_FAILED,
        payload: error.message
    };
}

function signoutSuccessfull () {
    return {
        type: USER_LOGGED_OUT
    };
}