import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios'
import {updateUserBalance} from '../actions/game-zone-action'
import {NotificationManager} from 'react-notifications';
import firebase from "firebase";
import './contact-form-css.css';
import FileUploader from "react-firebase-file-uploader";
import emailjs from 'emailjs-com';

var userin
var database
var auth = firebase.auth();
var user = firebase.auth().currentUser;
let template_params;
console.log("hereemail")
// console.log(user.email)
class MyVideos extends Component {

    constructor(props) {
        super(props)
        this.state = {
            name: '',
            phone: '',
            address: '',
            newpass: '',
            repeatpass: '',
            curname: '',
            curphone: '',
            curaddress: '',
            city: '',
            state: '',
            zip: '',
            country: '',
            avatar: '',
            isUploading: false,
            progress: 0,
            avatarURL: ""
        }
    }
    reauthenticate = (currentPassword) => {
        var user = firebase.auth().currentUser;
        var cred = firebase.auth.EmailAuthProvider.credential(
            user.email, currentPassword);
        return user.reauthenticateWithCredential(cred);
      }
    componentDidMount(){
        // var curuser = firebase.auth().currentUser;
        userin = firebase.auth().currentUser;
        database = firebase.database();
        
    }
    handleChange = (event) => {
        this.setState({
            [event.target.name]: [event.target.value][0]
        })

    }
    resetpassword = () => {
        // console.log(this.state.email[0]);
        // console.log(this.state.password[0]);
        var user = firebase.auth().currentUser;
        console.log(user.email);
        auth.sendPasswordResetEmail(user.email).then(function() {
          console.log("good");
          alert("Password Reset Email Sent");
          // Email sent.
        }).catch(function(error) {
          console.log("bad");
          alert("Error with your Email");
          // An error happened.
        });
      }

handleUploadStart = () => this.setState({ isUploading: true, progress: 0 });
handleProgress = progress => this.setState({ progress });
handleUploadError = error => {
    this.setState({ isUploading: false });
    console.error(error);
  };

handleUploadSuccess = filename => {
    this.setState({ avatar: filename, progress: 100, isUploading: false });
    firebase
      .storage()
      .ref("images")
      .child(filename)
      .getDownloadURL()
      .then(url => this.setState({ avatarURL: url }));
};
    rechargeUser = () => {
        template_params = {
            name: this.state.name,
            email: this.state.address,
            phone: this.state.phone,
            msg: this.state.city
        };
        emailjs.send('gmail','contact_form', template_params, 'user_NC0ghCSTpGuvJaacFz2yO')
    .then((response) => {
       alert('SUCCESS!');
    }, (err) => {
       console.log('FAILED...');
    });
    }
    render() {
        return (
            <div style = {styles.mediaObjects}>
                <form method="post" action="">

<h3>Contact Us</h3>
{/* <label>Avatar:</label>
          {this.state.isUploading && <p>Progress: {this.state.progress}</p>}
          {this.state.avatarURL && <img src={this.state.avatarURL} />} */}

<label for="username">Name</label>
<input type="text"  name="name" value = {this.state.name} placeholder={"Full Name"} autocomplete="off"  onChange = {this.handleChange}/>
<label for="username">Phone Number</label>
<input type="text" name="phone" value = {this.state.phone} placeholder= {"123456789"} autocomplete="off"  onChange = {this.handleChange}/>
<label for="username">Email</label>
<input type="text" id="address" name="address" value = {this.state.address} placeholder={"example@domain.com"} autocomplete="off"  onChange = {this.handleChange}/>
<label for="username">Message</label>
<textarea style = {styles.text_area} id="city" name="city" value = {this.state.city} placeholder={"Message"} autocomplete="off"  onChange = {this.handleChange}/>
<input type="button" name="submit" value="Send here" onClick = {this.rechargeUser}/>

</form>
            </div>
        );  
    };
};

function mapStateToProps({ mediaReducer, socialSignIn }) {
    return {
        user: socialSignIn,
        media: mediaReducer.media
    };
}
export default connect(mapStateToProps, {updateUserBalance})(MyVideos);

const styles = {
    mediaObjects: {
        flex: 7,
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap'
    },

};