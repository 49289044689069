import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios'
import {updateUserBalance} from '../actions/game-zone-action'
import {NotificationManager} from 'react-notifications';
import firebase from "firebase";
// import './form-css.css';
import logo from '../assets/realclaw.png';

var userin
var database
var auth = firebase.auth();
var user = firebase.auth().currentUser;
console.log("hereemail")
// console.log(user.email)
class MyVideos extends Component {

    constructor(props) {
        super(props)
        this.state = {
            name: '',
            phone: '',
            address: '',
            newpass: '',
            repeatpass: '',
            curname: '',
            curphone: '',
            curaddress: ''
        }
    }
    reauthenticate = (currentPassword) => {
        var user = firebase.auth().currentUser;
        var cred = firebase.auth.EmailAuthProvider.credential(
            user.email, currentPassword);
        return user.reauthenticateWithCredential(cred);
      }
    componentDidMount(){
    }
    handleChange = (event) => {
        this.setState({
            [event.target.name]: [event.target.value][0]
        })

    }
    resetpassword = () => {
        // console.log(this.state.email[0]);
        // console.log(this.state.password[0]);
        var user = firebase.auth().currentUser;
        console.log(user.email);
        auth.sendPasswordResetEmail(user.email).then(function() {
          console.log("good");
          alert("Password Reset Email Sent");
          // Email sent.
        }).catch(function(error) {
          console.log("bad");
          alert("Error with your Email");
          // An error happened.
        });
      }
    rechargeUser = () => {
        var userr = firebase.auth().currentUser;
        var database = firebase.database();

        console.log(userr.displayName);
        // userr.updateProfile({
        //     displayName: "Jane Q. User",
        //     photoURL: "https://example.com/jane-q-user/profile.jpg"
        //   }).then(function() {
        //       console.log('print')
        //     // Update successful.
        //   }).catch(function(error) {
        //     // An error happened.
        //     console.log('failed')
        //   });
        console.log(this.props.user.uid);

        console.log(this.state.name);
                if (this.state.name == ''){
                    console.log('name empty');
                }
                if (this.state.name != ''){
                    console.log('name not empty');
                    database.ref('users/'+ userr.uid).update({
                        displayName: this.state.name
                    });
                }
        console.log(this.state.phone);
                if (this.state.phone == ''){
                    console.log('phone empty');
                }
                if (this.state.phone != ''){
                    console.log('phone not empty');
                    database.ref('users/'+ userr.uid).update({
                        phone: this.state.phone
                    });
                }
        console.log(this.state.address);
                if (this.state.address == ''){
                    console.log('address empty');
                }
                if (this.state.address != ''){
                    console.log('address not empty');
                    database.ref('users/'+ userr.uid).update({
                        address: this.state.address
                    });
                }
        console.log(this.state.newpass);
        console.log(this.state.repeatpass);
                if (this.state.newpass == ''){
                    console.log('newpass empty');
                }
                if (this.state.newpass != ''){
                    console.log("this.state.newpass");
                    this.resetpassword();
                      }
                      
                alert("updated successfully")
                window.location.reload();
    }
    render() {
        return (
            <div style = {styles.mediaObjects}>
            <a href="/">
  <img src= {logo}></img>
  </a>

            <h2 style={{marginBottom: 30}}>Frequently Asked Questions</h2>
            <h3>What is Real Claw ?</h3>
            <p style={{marginLeft: 200, marginRight: 200, textAlign: "center"}}>Real Claw allows you to remotely play real crane games through the internet. Any prizes you have
won can be shipped to your place. Live videos is in placed, allowing you to to watch games played
by others.</p>
<h3>How do I play games in Real Claw ?</h3>
            <p style={{marginLeft: 200, marginRight: 200, textAlign: "center"}}>Login with your Facebook account first. Choose a product you're interested, then click start game.
That's it!</p>
<h3>When will the prizes change ?</h3>
            <p style={{marginLeft: 200, marginRight: 200, textAlign: "center"}}>Normally, prizes are rotated bi-weekly ;)</p>
<h3>How can I get the prizes after winning ?</h3>
            <p style={{marginLeft: 200, marginRight: 200, textAlign: "center"}}>After winning, your prizes are available under 'Prizes Won’ in ‘My Account'. Select the prizes you
want to ship and select your preferred shipping method. After you submitted the shipping, we will
process it within 3 days.</p>
<h3>How long will the prizes get to my home ?</h3>
            <p style={{marginLeft: 200, marginRight: 200, textAlign: "center"}}>It depends on the delivery method you choose. The range is 7 - 15 days.</p>
<h3>Why it says error occurred ?</h3    >
            <p style={{marginLeft: 200, marginRight: 200, textAlign: "center"}}>There can be several reasons for this. The internet may be unstable. And Real Claw is still in beta.
Software errors can unexpectedly occurred. If error occurred but you have not get refund, Please
contact us in-app or at admin@realclaw.com</p>
            </div>
        );  
    };
};

function mapStateToProps({ mediaReducer, socialSignIn }) {
    return {
        user: socialSignIn,
        media: mediaReducer.media
    };
}
export default connect(mapStateToProps, {updateUserBalance})(MyVideos);

const styles = {
    mediaObjects: {
        paddingTop: '30px',
        alignItems: 'center',
        display: 'flex',
        flexGrow: '1',
        flexDirection: 'column',
        justifyContent: 'center',
        marginBottom: '210px'
    }  
};