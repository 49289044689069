import * as FirebaseConfig from './firebase-config';

export function signInWithGoogle() {
    return FirebaseConfig.authProvider.signInWithPopup(FirebaseConfig.googleAuthProvider);
};

export function signInWithFaceBook() {
    return FirebaseConfig.authProvider.signInWithPopup(FirebaseConfig.facebookAuthProvider);
};

export function signout () {
    return FirebaseConfig.authProvider.signOut();
}

export function signUpWithEmailPassword (email, password) {
    return FirebaseConfig.firebase.auth().createUserWithEmailAndPassword(email, password).then(data => {
        return data.user
    }).catch(err => {
        console.log("error signin")
        return err.message
    })
}

export function signInWithEmailPassword (email, password) {
    return FirebaseConfig.firebase.auth().signInWithEmailAndPassword(email, password).then(data => {
        return data.user
    }).catch(err => {
        alert("Incorrect Email or Password")
        return (err) =>{
            alert(err)
            console.log(err.message);
        }
    })
}