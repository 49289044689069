import React from "react";
import GameRoom from './game-room';
import GameRoom2 from './game-room2';
import GameRoom3 from './game-room3';
import Recharge from './recharge';
import { Route } from 'react-router-dom';
import Sidebar from './sidebar';
import GameZone from './game-zone'
import accountedit from './accountedit';
import Back from './back.js'
import tos from './tos'
import privacypolicy from './privacypolicy'
import faq from './faq'
import contact from './contact'
import board from './board'
import play_history from './game_plays'
import account from './account'
// import buycredit from './buycredit'

const Home = () => {
    return (
      <div style = { styles.container }>
          <div style = { styles.sidebar }>
          <Sidebar/>
          <Route exact path="/" component={ Back} />
          <Route exact path="/home" component={ Back} />
          <Route exact = {true} path="/home/game/bigchoice" component={ GameRoom2 } />
          <Route exact = {true} path="/home/game/nightlife" component={ GameRoom } />
          <Route exact = {true} path="/home/game/lovegift" component={ GameRoom3 } />
          <Route exact path = "/home/recharge" component = { Recharge }/>
          <Route exact path = "/home/game-zone" component = { GameZone }/>
          <Route path="/home/accountedit" component={ accountedit } />
          <Route path="/home/account" component={ account } />
          <Route path="/home/tos" component={ tos } />
          <Route path="/home/privacypolicy" component={ privacypolicy } />
          <Route path="/home/faq" component={ faq } />
	  <Route path="/home/contact" component={ contact } />
    <Route path="/home/board" component={ board } />
    <Route path="/home/play_history" component={ play_history } />
          {/* <Route path="/home/buycredit" component={ buycredit } /> */}
          {/* <Route path="/home/signup" component={ signup } /> */}
          </div>
          <div style={styles.footer}><a href="/home/tos">Terms of Service</a> | <a href="/home/faq">FAQ</a> | <a href="/home/privacypolicy">Privacy Policy</a></div>
      </div>
    );
}

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column'
    },
    sidebar: {
        flex: 3,
        display: 'flex',
    },
    footer:{
        marginTop: '20',
        position: 'fixed',
        left: '0',
        bottom: '0',
        width: '100%',
        backgroundColor: 'gray',
        color: 'white',
        textAlign: 'center'
    }
};

export default Home;
