import React, { Component } from 'react';
import { connect } from 'react-redux';
import {updateUserBalance} from '../actions/game-zone-action'
import firebase from "firebase";
// import './form-css.css';
import logo from '../assets/realclaw.png';
// import privacypage from '../assets/privacy.html'
import Iframe from 'react-iframe'

import samplePDF from './faq.pdf';
var userin
var database
var auth = firebase.auth();
var user = firebase.auth().currentUser;
console.log("hereemail")
// console.log(user.email)
class MyVideos extends Component {

    state = {
        numPages: null,
      }

      onDocumentLoadSuccess = (document) => {
        const { numPages } = document;
        this.setState({
          numPages,
        });
      };

    constructor(props) {
        super(props)
        this.state = {
            name: '',
            phone: '',
            address: '',
            newpass: '',
            repeatpass: '',
            curname: '',
            curphone: '',
            curaddress: ''
        }
    }

handleChange = (event) => {
    this.setState({
        [event.target.name]: [event.target.value][0]
    })

}
    
    render() {
        const { pageNumber, numPages } = this.state;
        return (
            <div style = {styles.mediaObjects}>
            <a href="/">
  <img src= {logo}></img>
  </a>

            <h3>Privacy Policy</h3>
            <div>
            <p>Click <a href={samplePDF} target="_blank">Here</a> to download Privacy Policy</p>
      </div>
            {/* <img src= {logo}></img> */}

            </div>
        );  
    };
};

function mapStateToProps({ mediaReducer, socialSignIn }) {
    return {
        user: socialSignIn,
        media: mediaReducer.media
    };
}
export default connect(mapStateToProps, {updateUserBalance})(MyVideos);

const styles = {
    mediaObjects: {
        paddingTop: '30px',
        alignItems: 'center',
        display: 'flex',
        flexGrow: '1',
        flexDirection: 'column',
        justifyContent: 'center',
        marginBottom: '210px'
    }  
};