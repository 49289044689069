import React, {Component} from 'react'
import firebase from 'firebase';
import {connect} from 'react-redux'
import { Redirect } from 'react-router-dom'
const db = firebase.database();
var userin = firebase.auth().currentUser;
class GameZone extends Component {
    constructor(props) {
        super(props)
        this.state = {
            nightlife: [],
            lovegift: [],
            bigchoice: [],
            current: "",
            fetch_status:""
        }
    }

componentDidMount() {
  this.thisweek();
}

thisweek = () => {
    this.setState({
            nightlife: [],
            lovegift: [],
            bigchoice: [],
            current: "Showing This Week Board",
            fetch_status: "Please Wait while Data is being Fetched"
          })
      var that = this;
        var boardd = db.ref('nightlife_board_thisweek').orderByChild("wins")
        var boardd1 = db.ref('lovegift_board_thisweek').orderByChild("wins")
        var boardd2 = db.ref('bigchoice_board_thisweek')



        boardd.once('value', function(snapshot) {

        let data = [];
        snapshot.forEach(function(child) {
          data.push(child.val());
        });

      setTimeout(function(){
        if (data.length == 0){
           that.setState({
            fetch_status: "Not Enough Data"
          })
        }
        else{
           that.setState({
            nightlife: data.reverse(),
          })
           }
           
        }, 5000);
      
      });
        // NIGHT LIFE
      //   boardd.once('value', function(snapshot) {

      //   let data = [];
      //   snapshot.forEach(function(child) {
      //     console.log('here is child:', child)
      //     // data.push(child.val());
      //   });

      // setTimeout(function(){
      //   if (data.length == 0){
      //      that.setState({
      //       fetch_status: "Not Enough Data"
      //     })
      //   }
      //   else{
      //      that.setState({
      //       nightlife: data.reverse(),
      //     })
      //      }
           
      //   }, 5000);
      
      // });

      // NIGHT LIFE ENDDDDD
      // --------------------------------
       // LOVE GIFT

       boardd1.once('value', function(snapshot1) {

        let data1 = [];
        snapshot1.forEach(function(child) {
          data1.push(child.val());
        });


      setTimeout(function(){
        console.log('datalength', data1.length)
           if (data1.length === 0){
           that.setState({
            fetch_status: "Not Enough Data"
          })
        }
        else{
           that.setState({
            lovegift: data1.reverse(),
          })
           }
        }, 5000);
      
      });

       // LOVE GIFTTTT ENDDDDD

       // BIGGG CHOICEEEE


       boardd2.orderByChild('wins').once('value', function(snapshot2) {
        let data2 = [];
        // let data2
        snapshot2.forEach(function(child) {
          data2.push(child.val());
        });

      setTimeout(function(){
            if (data2.length === 0){
           that.setState({
            fetch_status: "Not Enough Data"
          })
        }
        else{
           that.setState({
            bigchoice: data2.reverse(),
          })
           }
        }, 5000);
      
      });


       // BIGG CHOICEEEE ENDDDD
    
    }

    previousweek = () => {
        this.setState({
            nightlife: [],
            lovegift: [],
            bigchoice: [],
            current: "Showing Previous Week Board",
            fetch_status: "Please Wait while Data is being Fetched"
          })
      var that = this;
        var boardd = db.ref('nightlife_board_lastweek').orderByChild("wins")
        var boardd1 = db.ref('lovegift_board_lastweek').orderByChild("wins")
        var boardd2 = db.ref('bigchoice_board_lastweek')


        // NIGHT LIFE
        boardd.once('value', function(snapshot) {

        let data = [];
        snapshot.forEach(function(child) {
          data.push(child.val());
        });

      setTimeout(function(){
        if (data.length == 0){
           that.setState({
            fetch_status: "Not Enough Data"
          })
        }
        else{
           that.setState({
            nightlife: data.reverse(),
          })
           }
           
        }, 5000);
      
      });

      // NIGHT LIFE ENDDDDD
      // --------------------------------
       // LOVE GIFT

       boardd1.once('value', function(snapshot1) {

        let data1 = [];
        snapshot1.forEach(function(child) {
          data1.push(child.val());
        });


      setTimeout(function(){
        console.log('datalength', data1.length)
           if (data1.length === 0){
           that.setState({
            fetch_status: "Not Enough Data"
          })
        }
        else{
           that.setState({
            lovegift: data1.reverse(),
          })
           }
        }, 5000);
      
      });

       // LOVE GIFTTTT ENDDDDD

       // BIGGG CHOICEEEE


       boardd2.orderByChild('wins').once('value', function(snapshot2) {
        let data2 = [];
        // let data2
        snapshot2.forEach(function(child) {
          data2.push(child.val());
        });

      setTimeout(function(){
            if (data2.length === 0){
           that.setState({
            fetch_status: "Not Enough Data"
          })
        }
        else{
           that.setState({
            bigchoice: data2.reverse(),
          })
           }
        }, 5000);
      
      });


       // BIGG CHOICEEEE ENDDDD
    
    }



    alltimeboard = () => {
        this.setState({
            nightlife: [],
            lovegift: [],
            bigchoice: [],
            current: "Showing All Time Board",
            fetch_status: "Please Wait while Data is being Fetched"
          })
      var that = this;
        var boardd = db.ref('nightlife_board').orderByChild("wins")
        var boardd1 = db.ref('lovegift_board').orderByChild("wins")
        var boardd2 = db.ref('bigchoice_board')


        // NIGHT LIFE
        boardd.once('value', function(snapshot) {

        let data = [];
        snapshot.forEach(function(child) {
          data.push(child.val());
        });

      setTimeout(function(){
        if (data.length == 0){
           that.setState({
            fetch_status: "Not Enough Data"
          })
        }
        else{
           that.setState({
            nightlife: data.reverse(),
          })
           }
           
        }, 5000);
      
      });

      // NIGHT LIFE ENDDDDD
      // --------------------------------
       // LOVE GIFT

       boardd1.once('value', function(snapshot1) {

        let data1 = [];
        snapshot1.forEach(function(child) {
          data1.push(child.val());
        });


      setTimeout(function(){
        console.log('datalength', data1.length)
           if (data1.length === 0){
           that.setState({
            fetch_status: "Not Enough Data"
          })
        }
        else{
           that.setState({
            lovegift: data1.reverse(),
          })
           }
        }, 5000);
      
      });

       // LOVE GIFTTTT ENDDDDD

       // BIGGG CHOICEEEE


       boardd2.orderByChild('wins').once('value', function(snapshot2) {
        let data2 = [];
        // let data2
        snapshot2.forEach(function(child) {
          data2.push(child.val());
        });

      setTimeout(function(){
            if (data2.length === 0){
           that.setState({
            fetch_status: "Not Enough Data"
          })
        }
        else{
           that.setState({
            bigchoice: data2.reverse(),
          })
           }
        }, 5000);
      
      });


       // BIGG CHOICEEEE ENDDDD
    }



    render() {
        return (
            <div style = {styles.container}>
        <div class="container-fluid">
        <div class="row">
        <div class="col-md-4">
        <center>
                <form method="post" action="">
          <input type="button" name="submit" value="This Week" onClick = {() => { this.thisweek() }}/>
          </form>
        </center>
        </div>
        <div class="col-md-4">
                <center>
                <form method="post" action="">
          <input type="button" name="submit" value="All Time" onClick = {() => { this.alltimeboard() }}/>
          </form>
        </center>
</div>
<div class="col-md-4">
<center>
                <form method="post" action="">
          <input type="button" name="submit" value="Previous Week" onClick = {() => { this.previousweek() }}/>
          </form>
        </center>
</div>
        </div>
        <div class="row">
        <div class="col-md-4"></div>
        <div class="col-md-4">
                <center>
          <h3>{this.state.current}</h3>
        </center>
</div>
<div class="col-md-4"></div>
        </div>
  <div class="row">
    <div class="col-md-4">
      
        <div>
             </div>
  <center>
<h4>Big Choice</h4>
{
    this.state.bigchoice.length > 0 ?
            <div class = "row">
            <div style = {styles1.flexDisplay}>
      
                <div class="col-md-4">
              
                 
                </div>
                <div style = {{marginLeft:20}} class="col-md-4">
                    <center>score</center>
                </div> 
                <div  class="col-md-8">
                 Name
                </div>                               
            </div>
            </div>

    : <div><h5></h5></div>
  }
{
    this.state.bigchoice.length > 0 ?this.state.bigchoice.map((user3, index) => {
        
        return (
            <div class = "row">
            <div style = {styles1.flexDisplay}>
      
                <div class="col-md-4">
                <img src = {user3.photoURL} style = {styles1.img}/>
                 
                </div>
                <div class="col-md-4">
                    {user3.wins}
                </div> 
                <div class="col-md-8">
                  {user3.name}
                </div>                               
            </div>
            </div>
            
        )
    })
    : <div><h5>{this.state.fetch_status}</h5></div>}
</center>

    </div>
    <div class="col-md-4">
      
        <div>
             </div>
  <center>
<h4>Love Gift</h4>
{
    this.state.lovegift.length > 0 ?
            <div class = "row">
            <div style = {styles1.flexDisplay}>
      
                <div class="col-md-4">
              
                 
                </div>
                <div style = {{marginLeft:20}} class="col-md-4">
                    <center>score</center>
                </div> 
                <div  class="col-md-8">
                 Name
                </div>                               
            </div>
            </div>

    : <div><h5></h5></div>
  }
{
    this.state.lovegift.length > 0 ?this.state.lovegift.map((user2, index) => {
        
        return (
            <div class = "row">
            <div style = {styles1.flexDisplay}>
      
                <div class="col-md-4">
                <img src = {user2.photoURL} style = {styles1.img}/>
                 
                </div>
                <div class="col-md-4">
                    {user2.wins}
                </div> 
                <div class="col-md-8">
                  {user2.name}
                </div>                               
            </div>
            </div>
            
        )
    })
    : <div><h5>{this.state.fetch_status}</h5></div>}
</center>

    </div>
    <div class="col-md-4">
      
        <div>
             </div>
  <center>
<h4>Night Life</h4>
{
    this.state.nightlife.length > 0 ?
            <div class = "row">
            <div style = {styles1.flexDisplay}>
      
                <div class="col-md-4">
              
                 
                </div>
                <div style = {{marginLeft:20}} class="col-md-4">
                    <center>score</center>
                </div> 
                <div  class="col-md-8">
                 Name
                </div>                               
            </div>
            </div>

    : <div><h5></h5></div>
  }
{
    this.state.nightlife.length > 0 ?this.state.nightlife.map((user1, index) => {
        
        return (
            <div class = "row">
            <div style = {styles1.flexDisplay}>
      
                <div class="col-md-4">
                <img src = {user1.photoURL} style = {styles1.img}/>
                 
                </div>
                <div class="col-md-4">
                    {user1.wins}
                </div> 
                <div class="col-md-8">
                  {user1.name}
                </div>                               
            </div>
            </div>
            
        )
    })
    : <div><h5>{this.state.fetch_status}</h5></div>}
</center>

    </div>
  </div>

</div>

            </div>
        )
    }
}

const styles1 = {
    flexDisplay: {
        display: 'flex',
        marginTop: 5,
        marginBotton: 5,
        fontWeight: 'bold'
    },
    subContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        marginTop: '30px'
    },
    img: {
        marginTop: 5,
        marginBotton: 5,
        marginLeft: 5,
        height: '50px',
        width: '50px'
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        marginLeft: '500px'
    }
    
}

const styles = {
    flexDisplay: {
        display: 'flex',
        marginTop: 5,
        marginBotton: 5,
        fontWeight: 'bold'
    },
    mediaObjects: {
        width: '300px'
    },
    subContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        marginTop: '30px'
    },
    img: {
        marginTop: 5,
        marginBotton: 5,
        marginLeft: 5,
        height: '50px',
        width: '50px'
    },
    container: {
        paddingTop: '30px',
        marginLeft: '100px',
        alignItems: 'center',
        display: 'flex',
        flexGrow: '1',
        flexDirection: 'column',
        justifyContent: 'center'
        
    }
    
}
const mapStateToProps = (state) => {
    return state;
}
export default connect(mapStateToProps)(GameZone)