import { ALL_MEDIA, UPLOADING_MEDIA, UPLOAD_SUCCESFULL, USER_REMOVED_FROM_GAME, GAME_BUTTON_VALUES} from '../actions/media-action';

const INITIAL_STATE = { media: '', uploading: false, redirect: false, Up: false, Down: false, Right: false, Left: false, message: 'Welcome to game room'};

export default function (state = {} , action) {
    switch (action.type) {
        case ALL_MEDIA:
            INITIAL_STATE.media = action.payload;
            INITIAL_STATE.redirect =false
            return state = {...INITIAL_STATE};
        case UPLOADING_MEDIA:
            INITIAL_STATE.uploading = true;
            INITIAL_STATE.redirect = false
            return state = {...INITIAL_STATE};
        case UPLOAD_SUCCESFULL:
            INITIAL_STATE.uploading = false;
            INITIAL_STATE.redirect = false
            return state = {...INITIAL_STATE};
        case USER_REMOVED_FROM_GAME:
            INITIAL_STATE.uploading=  false
            INITIAL_STATE.redirect = true
            return state = {...INITIAL_STATE}
        case GAME_BUTTON_VALUES: 
            INITIAL_STATE.Up = action.payload.Up;
            INITIAL_STATE.Down = action.payload.Down;
            INITIAL_STATE.Right = action.payload.Right;
            INITIAL_STATE.Left = action.payload.Left;
            INITIAL_STATE.message = action.payload.message
            return state = {...INITIAL_STATE}
        default:
            return state;
    }
}