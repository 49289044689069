import React, {Component} from 'react'
import firebase from 'firebase';
import {connect} from 'react-redux'
import { getUsersList} from '../actions/game-zone-action'
import { Redirect } from 'react-router-dom'
import claw1 from '../assets/icons/claw1.jpg';
import claw2 from '../assets/icons/claw2.jpg';
import claw3 from '../assets/icons/claw3.jpg';
export const UPLOADING_MEDIA = 'UPLOADING_MEDIA';
export const USER_REMOVED_FROM_GAME = 'USER_REMOVED_FROM_GAME'
const fbdatabase = firebase.database();
var userin = firebase.auth().currentUser;
class GameZone extends Component {
    constructor(props) {
        super(props)
        this.state = {
            usersList: []
        }
    }
    componentDidMount() {
        this.props.getUsersList()
    }
    componentWillReceiveProps(nextProps) {
        if(nextProps.UserList.data) {
            this.setState({
                usersList: nextProps.UserList.data
            }, () => {
            })
        }
    }

    bigchoice = () => {
        return this.props.history.push('/home/game/bigchoice')
    }
    nightlife = () => {
        return this.props.history.push('/home/game/nightlife')
    }
    lovegift = () => {
        return this.props.history.push('/home/game/lovegift')
    }


    render() {
        return (
            <div style = {styles.container}>
        <div class="container-fluid">
	<div class="row">
		<div class="col-md-4">
			
    <div>
   <a  onClick = {this.bigchoice}>         
  <img style = {styles.mediaObjects} src= {claw1}></img>
   </a> </div>
  <center>
<h4>Big Choice</h4>
</center>
		</div>
		<div class="col-md-4">
			
        <div onClick = {this.lovegift}>
            <a>
  <img style = {styles.mediaObjects} src= {claw2}></img>
  </a> </div>
  <center>
<h4>Love Gift</h4>
</center>
		</div>
		<div onClick = {this.nightlife} class="col-md-4">
            <a>
  <img style = {styles.mediaObjects} src= {claw3}></img>
  </a>
  <center>
<h4>Night Life</h4>
</center>
		</div>
	</div>
</div>
            </div>
        )
    }
}

const styles1 = {
    flexDisplay: {
        display: 'flex',
        marginTop: 5,
        marginBotton: 5,
        fontWeight: 'bold'
    },
    subContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        marginTop: '30px'
    },
    img: {
        marginTop: 5,
        marginBotton: 5,
        marginLeft: 5,
        height: '50px',
        width: '50px'
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        marginLeft: '50px'
    }
    
}

const styles = {
    flexDisplay: {
        display: 'flex',
        marginTop: 5,
        marginBotton: 5,
        fontWeight: 'bold'
    },
    mediaObjects: {
        width: '300px'
    },
    subContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        marginTop: '30px'
    },
    img: {
        marginTop: 5,
        marginBotton: 5,
        marginLeft: 5,
        height: '50px',
        width: '50px'
    },
    container: {
        paddingTop: '30px',
        marginLeft: '100px',
        alignItems: 'center',
        display: 'flex',
        flexGrow: '1',
        flexDirection: 'column',
        justifyContent: 'center'
        
    }
    
}
const mapStateToProps = (state) => {
    return state;
}
export default connect(mapStateToProps, {getUsersList})(GameZone)