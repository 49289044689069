import React from "react";
import login from './login';
import signup from './signup';
import forget from './forgett';
import { Route } from 'react-router-dom';
import tos from './tos'
import privacypolicy from './privacypolicy'
import faq from './faq'
import contact from './contact'

const Home2 = () => {
    return (
      <div style = { styles.container }>
          <Route exact path="/" component={ login} />
          {/* <Route exact path="/login" component={ forget} /> */}
          <Route  path = "/forget" component = { forget }/>
          <Route path="/signup" component={ signup } />
          <Route path="/tos" component={ tos } />
          <Route path="/privacypolicy" component={ privacypolicy } />
          <Route path="/faq" component={ faq } />
	  <Route path="/contact" component={ contact } />
          {/* <Route path="/home/signup" component={ signup } /> */}
          <div style={styles.footer}><a href="/tos">Terms of Service</a> | <a href="/faq">FAQ</a> | <a href="/privacypolicy">Privacy Policy</a></div>
      </div>
    );
}

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column'
    },
    sidebar: {
        flex: 3,
        display: 'flex',
    },
    footer:{
        marginTop: '20',
        position: 'fixed',
        left: '0',
        bottom: '0',
        width: '100%',
        backgroundColor: 'gray',
        color: 'white',
        textAlign: 'center'
    }
};

export default Home2;
