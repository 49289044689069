import { combineReducers } from 'redux';
import socialSignIn from './signin_reducer';
import GameZone from './game-zone'
import mediaReducer from './media-reducer';
import UserList from './user-list'
const rootReducer = combineReducers({
    socialSignIn,
    mediaReducer,
    GameZone,
    UserList
});
export default rootReducer;