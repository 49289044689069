import React, { Component } from 'react';
import { connect } from 'react-redux';
import {updateUserBalance} from '../actions/game-zone-action'
// import './form-css.css'; 
import toss from '../assets/tos.pdf';
import logo from '../assets/realclaw.png';

class MyVideos extends Component {



    render() {
        return (
            <div style = {styles.mediaObjects}>
<a href="/">
  <img src= {logo}></img>
  </a>
            <h2>Terms of Service</h2>
            <div>
            <p>Click <a href={toss} target="_blank">Here</a> to download Terms of Service</p>
      </div>

            </div>
        );  
    };
};

function mapStateToProps({ mediaReducer, socialSignIn }) {
    return {
        user: socialSignIn,
        media: mediaReducer.media
    };
}
export default connect(mapStateToProps, {updateUserBalance})(MyVideos);

const styles = {
    mediaObjects: {
        paddingTop: '30px',
        alignItems: 'center',
        display: 'flex',
        flexGrow: '1',
        flexDirection: 'column',
        justifyContent: 'center',
        marginBottom: '210px'
    }  
};