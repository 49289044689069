import firebase from "firebase";
const fbdatabase = firebase.database();

export function removeUserFromList(userId) {
    const usersRef = fbdatabase.ref(`queue3/${userId}`);
    return usersRef.remove()
}

export function addUserToGameZone (user) {
    const usersRef = fbdatabase.ref(`queue3/${user.uid}`);
    return new Promise((resolve, reject) => {
        usersRef.once("value", function(_user) {
            if(!_user.val()) {
                user.createdAt =firebase.database.ServerValue.TIMESTAMP
                resolve(usersRef.set(user))
            }
            else resolve({})
        })
    })
}