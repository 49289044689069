import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios'
import {updateUserBalance} from '../actions/game-zone-action'
import {NotificationManager} from 'react-notifications';
import StripeCheckout from "react-stripe-checkout";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import './form-css.css';
import firebase from 'firebase';
const fbdatabase = firebase.database();
export const USER_LOGIN_SUCCESSFUL = 'USER_LOGIN_SUCCESSFUL'
let product
let curbal
let curuid
// const [product] = React.useState({
//     name: "Tesla Roadster",
//     price: 64998.67,
//     description: "Cool car"
//   });
class MyVideos extends Component {
    
    

    constructor(props) {
        super(props)
        this.state = {
            price1: 50,
            price2: 100
        }
    }
    // componentWillMount() {
    //     if ( !window.Stripe ) {
    //       const stripeJs = document.createElement( 'script' )
    //       stripeJs.src = 'https://js.stripe.com/v3/'
    //       stripeJs.async = true
    //       document.body && document.head.appendChild( stripeJs )
    //     }
    //   }
     updateUserBalance(userId, balance) {
        const userRef = fbdatabase.ref(`users/${userId}`);
        return (dispatch) => {
            return userRef.update({balance: balance}).then((user) => {
                userRef.once("value", function(_user) {
                    if(_user.val()) {
                        dispatch({
                            type: USER_LOGIN_SUCCESSFUL,
                            payload: _user.val()
                        })
                    }
                    
                })
            })
            
        }
    
    }

    async  handleToken(token, addresses) {
        const response = await axios.post(
          "https://pay.realclaw.com/checkout",
          { token, product }
        );
        const { status } = response.data;
        console.log("Response:", response.data);
        if (status == "success") {
          var userin = firebase.auth().currentUser;
            // curbal = userin.balance;


            curuid = userin.uid;
            var starCountRef = firebase.database().ref('users/' + curuid + '/balance');
            starCountRef.on('value', function(snapshot) {
            curbal = snapshot.val();
            });
            console.log('curbalance:' + curbal)
            console.log('curuid:' + curuid)
          toast("Success! Check email for details", { type: "success" });
          let total =  600 + curbal
          console.log('totalbal:' + total)
        //   updateUserBalance(curuid, total.toFixed(2))
        firebase.database().ref('users/' + curuid).update({
            balance: total,
          }, function(error) {
            if (error) {
              // The write failed...
              alert("Failed")
            } else {
              // Data saved successfully!
              alert("Recharged Successfully, Please refresh your page")
              window.location.reload();
            }
          });
        

        } else {
          toast("Something went wrong", { type: "error" });
        }
      }
      async  handleToken2(token, addresses) {
        const response = await axios.post(
          "https://pay.realclaw.com/checkout2",
          { token, product }
        );
        const { status } = response.data;
        console.log("Response:", response.data);
        if (status == "success") {
          toast("Success! Check email for details", { type: "success" });
          var userin = firebase.auth().currentUser;
            // curbal = userin.balance;
            curuid = userin.uid;
            var starCountRef = firebase.database().ref('users/' + curuid + '/balance');
            starCountRef.on('value', function(snapshot) {
            curbal = snapshot.val();
            });
            console.log('curbalance:' + curbal)
            console.log('curuid:' + curuid)
          let total =  1650 + curbal
          console.log('totalbal:' + total)
        //   updateUserBalance(curuid, total.toFixed(2))
        firebase.database().ref('users/' + curuid).update({
            balance: total,
          }, function(error) {
            if (error) {
              // The write failed...
              alert("Failed")
            } else {
              // Data saved successfully!
              alert("Recharged Successfully, Please refresh your page")
              window.location.reload();
            }
          });
        //   this.props.updateUserBalance(curuid, total.toFixed(2))
        } else {
          toast("Something went wrong", { type: "error" });
        }
      }

      async  handleToken3(token, addresses) {
        const response = await axios.post(
          "https://pay.realclaw.com/checkout3",
          { token, product }
        );
        const { status } = response.data;
        console.log("Response:", response.data);
        if (status == "success") {
          toast("Success! Check email for details", { type: "success" });
          var userin = firebase.auth().currentUser;
            // curbal = userin.balance;
            curuid = userin.uid;
            var starCountRef = firebase.database().ref('users/' + curuid + '/balance');
            starCountRef.on('value', function(snapshot) {
            curbal = snapshot.val();
            });
            console.log('curbalance:' + curbal)
            console.log('curuid:' + curuid)
          let total =  3300 + curbal
          console.log('totalbal:' + total)
        //   updateUserBalance(curuid, total.toFixed(2))
        firebase.database().ref('users/' + curuid).update({
            balance: total,
          }, function(error) {
            if (error) {
              // The write failed...
              alert("Failed")
            } else {
              // Data saved successfully!
              alert("Recharged Successfully, Please refresh your page")
              window.location.reload();
            }
          });
        //   this.props.updateUserBalance(curuid, total.toFixed(2))
        } else {
          toast("Something went wrong", { type: "error" });
        }
      }

      async  handleToken4(token, addresses) {
        const response = await axios.post(
          "https://pay.realclaw.com/checkout4",
          { token, product }
        );
        const { status } = response.data;
        console.log("Response:", response.data);
        if (status == "success") {
          toast("Success! Check email for details", { type: "success" });
          var userin = firebase.auth().currentUser;
            // curbal = userin.balance;
            curuid = userin.uid;
            var starCountRef = firebase.database().ref('users/' + curuid + '/balance');
            starCountRef.on('value', function(snapshot) {
            curbal = snapshot.val();
            });
            console.log('curbalance:' + curbal)
            console.log('curuid:' + curuid)
          let total =  8250 + curbal
          console.log('totalbal:' + total)
        //   updateUserBalance(curuid, total.toFixed(2))
        firebase.database().ref('users/' + curuid).update({
            balance: total,
          }, function(error) {
            if (error) {
              // The write failed...
              alert("Failed")
            } else {
              // Data saved successfully!
              alert("Recharged Successfully, Please refresh your page")
              window.location.reload();
            }
          });
        //   this.props.updateUserBalance(curuid, total.toFixed(2))
        } else {
          toast("Something went wrong", { type: "error" });
        }
      }

      async  handleToken5(token, addresses) {
        const response = await axios.post(
          "https://pay.realclaw.com/checkout5",
          { token, product }
        );
        const { status } = response.data;
        console.log("Response:", response.data);
        if (status == "success") {
          toast("Success! Check email for details", { type: "success" });
          var userin = firebase.auth().currentUser;
            // curbal = userin.balance;
            curuid = userin.uid;
            var starCountRef = firebase.database().ref('users/' + curuid + '/balance');
            starCountRef.on('value', function(snapshot) {
            curbal = snapshot.val();
            });
            console.log('curbalance:' + curbal)
            console.log('curuid:' + curuid)
          let total =  12300 + curbal
          console.log('totalbal:' + total)
        //   updateUserBalance(curuid, total.toFixed(2))
        firebase.database().ref('users/' + curuid).update({
            balance: total,
          }, function(error) {
            if (error) {
              // The write failed...
              alert("Failed")
            } else {
              // Data saved successfully!
              alert("Recharged Successfully, Please refresh your page")
              window.location.reload();
            }
          });
        //   this.props.updateUserBalance(curuid, total.toFixed(2))
        } else {
          toast("Something went wrong", { type: "error" });
        }
      }

      async  handleToken6(token, addresses) {
        const response = await axios.post(
          "https://pay.realclaw.com/checkout6",
          { token, product }
        );
        const { status } = response.data;
        console.log("Response:", response.data);
        if (status == "success") {
          toast("Success! Check email for details", { type: "success" });
          var userin = firebase.auth().currentUser;
            // curbal = userin.balance;
            curuid = userin.uid;
            var starCountRef = firebase.database().ref('users/' + curuid + '/balance');
            starCountRef.on('value', function(snapshot) {
            curbal = snapshot.val();
            });
            console.log('curbalance:' + curbal)
            console.log('curuid:' + curuid)
          let total =  18000 + curbal
          console.log('totalbal:' + total)
        //   updateUserBalance(curuid, total.toFixed(2))
        firebase.database().ref('users/' + curuid).update({
            balance: total,
          }, function(error) {
            if (error) {
              // The write failed...
              alert("Failed")
            } else {
              // Data saved successfully!
              alert("Recharged Successfully, Please refresh your page")
              window.location.reload();
            }
          });
        //   this.props.updateUserBalance(curuid, total.toFixed(2))
        } else {
          toast("Something went wrong", { type: "error" });
        }
      }


    // async  handleToken(token, addresses) {
    //     // const url = 'https://gameplay-app.herokuapp.com/charge/'+this.props.user.uid
    //     // return axios.post(url, this.state).then(response => {
    //     //     let total =  parseFloat(this.state.amount) + parseFloat(this.props.user.balance)
    //     //     this.props.updateUserBalance(this.props.user.uid, total.toFixed(2))
    //     //     alert("Recharged Successfully, Please refresh your page")

    //     // }).catch (err => {
    //     //     alert(err.response.data.error)
    //     //     NotificationManager.error(err.response.data.error, 'Error');
    //     // })
    //     return axios.post("localhost:8080/checkout",{ token, product }).then(response => {
    //         if (response.data === "success") {
    //             toast("Success! Check email for details", { type: "success" });
    //           } else {
    //             toast("Something went wrong", { type: "error" });
    //           }
    //       }).catch(err=>{
    //         console.log(err.response.data.error)
    //       })
    //     //   const { status } = response.data;
    //     //   console.log("Response:", response.data);
    //     //   if (status === "success") {
    //     //     toast("Success! Check email for details", { type: "success" });
    //     //   } else {
    //     //     toast("Something went wrong", { type: "error" });
    //     //   }
    //     }
    
    handleChange = (event) => {
        this.setState({
            [event.target.name]: [event.target.value][0]
        })

    }
    
    render() {
        return (
            <div style = {styles.mediaObjects}>
            <div style= {Object.assign({marginLeft: "100px"})}>
            <div className="container">
            <h2 style = {styles.paybutton}>600 Tokens - $4.99</h2>
      <StripeCheckout
        stripeKey="pk_live_UXmal3SHKmdpygMgOrrXaarm"
        token={this.handleToken}
        amount={499}
        name="600 Tokens - $4.99"
        billingAddress
      />
    </div>
            <div className="container">
            <h2 style = {styles.paybutton}>1,650 Tokens - $9.99</h2>
      <StripeCheckout
        stripeKey="pk_live_UXmal3SHKmdpygMgOrrXaarm"
        token={this.handleToken2}
        amount={999}
        name="1,650 Tokens - $9.99"
        billingAddress
      />
    </div>
    </div>
    <div style= {Object.assign({marginLeft: "50px"})}>
    <div className="container">
      <h2 style = {styles.paybutton}>3,300 Tokens - $19.99</h2>
      <StripeCheckout
        stripeKey="pk_live_UXmal3SHKmdpygMgOrrXaarm"
        token={this.handleToken3}
        amount={1999}
        name="3,300 Tokens - $19.99"
        billingAddress
      />
    </div>
    <div className="container">
      <h2 style = {styles.paybutton}>8,250 Tokens - $49.99</h2>
      <StripeCheckout
        stripeKey="pk_live_UXmal3SHKmdpygMgOrrXaarm"
        token={this.handleToken4}
        amount={4999}
        name="8,250 Tokens - $49.99"
        billingAddress
      />
    </div>
    </div>
    <div style= {Object.assign({marginLeft: "50px"})}>
    <div className="container">
      <h2 style = {styles.paybutton}>12,300 Tokens - $74.99</h2>
      <StripeCheckout
        stripeKey="pk_live_UXmal3SHKmdpygMgOrrXaarm"
        token={this.handleToken5}
        amount={7499}
        name="12,300 Tokens - $74.99"
        billingAddress
      />
    </div>
    <div className="container">
      <h2 style = {styles.paybutton}>18,000 Tokens - $99.99</h2>
      <StripeCheckout
        stripeKey="pk_live_UXmal3SHKmdpygMgOrrXaarm"
        token={this.handleToken6}
        amount={9999}
        name="18,000 Tokens - $99.99"
        billingAddress
      />
    </div>
    </div>
{/* //                 <form method="post" action="">

// <h3>Recharge Your Credit</h3>
// <label for="username">Name On Card</label>
// <input type="text"  name="nameoncard" value = {this.state.nameoncard} placeholder="Your Name" autocomplete="off"  onChange = {this.handleChange}/>
// <label for="username">Card Number</label>
// <input type="text"  name="number" value = {this.state.number} placeholder="Type in your Card Number" autocomplete="off"  onChange = {this.handleChange}/>
// <label for="username">Zip Code</label>
// <input type="text"  name="zipcode" value = {this.state.zipcode} placeholder="Address Zip Code" autocomplete="off"  onChange = {this.handleChange}/>
// <label for="username">Exp Month</label>
// <input type="text" name="expMonth" value = {this.state.expMonth} placeholder="Type in your Exp Month" autocomplete="off"  onChange = {this.handleChange}/>
// <label for="username">Exp Year</label>
// <input type="text" id="username" name="expYear" value = {this.state.expYear} placeholder="Type in your Exp Year" autocomplete="off"  onChange = {this.handleChange}/>
// <label for="password">CVC</label>
// <input type="password" id="password" name="cvc" value = {this.state.cvc} placeholder="CVC" autocomplete="off"  onChange = {this.handleChange}/>
// <label for="username">Amount</label>
// <input type="text" id="username" name="amount" value = {this.state.amount} placeholder="Type in your username.." autocomplete="off"  onChange = {this.handleChange}/>


// <input type="button" name="submit" value="Recharge" onClick = {this.rechargeUser}/>

// </form> */}
            </div>
        );  
    };
};

function mapStateToProps({ mediaReducer, socialSignIn }) {
    return {
        user: socialSignIn,
        media: mediaReducer.media
    };
}
// export default connect(mapStateToProps, {updateUserBalance})(MyVideos);
export default connect(mapStateToProps, {updateUserBalance})(MyVideos);
const styles = {
    mediaObjects: {
        flex: 7,
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      marginTop: '100px'
    },
    paybutton: {
        marginBottom: '50px'
    }
};
