import React, { Component } from "react";
import { changeStateOfButton, leaveGameRoom,getButtonState } from '../actions/media-action';
import {addUserToGame, getUsersList, updateUserBalance, updateUserEntryInGameRoom} from '../actions/game-zone-action'
import { connect } from 'react-redux';
import './hover-css.css'
import firebase from 'firebase';
import Sound from 'react-sound';
import Iframe from 'react-iframe'
import down from '../assets/icons/down.png'
import up from '../assets/icons/up.png'
import left from '../assets/icons/left.png'
import right from '../assets/icons/right.png'
import grab from '../assets/icons/grabb.png'
import start from '../assets/icons/startt.png'
import restart from '../assets/icons/restart.png'
import exit from '../assets/icons/exit.png'
import soundon from '../assets/icons/sound_open.png'
import soundoff from '../assets/icons/sound_close.png'
import camera from '../assets/icons/changecamera.png'
import musicc from '../assets/music.mp3'
import dropmusic from '../assets/dropclaw.m4a'
import navmusic from '../assets/updownleftright.mp3'
import winmusic from '../assets/prizewon.m4a'
import lossmusic from '../assets/noprize.mp3'
import startmusic from '../assets/startgame.m4a'
export const UPLOADING_MEDIA = 'UPLOADING_MEDIA';
export const USER_REMOVED_FROM_GAME = 'USER_REMOVED_FROM_GAME'

let msgref = firebase.database().ref('machine1/prize');



export {timerr, timerr1, timerstart}


var test = "no"
let timerr
let timerr_1
let timerr1_1
let timerr1
let congotimerr
let newtime
let timerstart
let camm = 1
let soundd = 1
let inientry = 0
let congokick = '0'
let enteredd = 0
let sad_stop

class MediaUpload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            buttons: {
            Up: 0,
            message: 'Welcome To Game Room',
            Left: 0,
            Down: 0,
            Right: 0,
            DropClaw: 0,
            InsertCoin: 0,
            Start: 0,
            },
            totalplay: 0,
            thisplay: 0,
            thisloss: 0,
            thiswin: 0,
            usersList: [],
            usersList1: [],
            position: 0,
            volume: 100,
            playbackRate: 1,
            loop: true,
            playStatus: Sound.status.PAUSED,
            msg1: 'Congratss',
            msg2: 'Sorry, Try ',
            a_position: 0,
            a_volume: 100,
            a_playbackRate: 1,
            a_loop: true,
            b_position: 0,
            b_volume: 100,
            b_playbackRate: 1,
            b_loop: true,
            c_position: 0,
            c_volume: 100,
            c_playbackRate: 1,
            c_loop: false,
            drop_playStatus: Sound.status.PAUSED,
            nav_playStatus: Sound.status.PAUSED,
            win_playStatus: Sound.status.PAUSED,
            loss_playStatus: Sound.status.PAUSED,
            start_playStatus: Sound.status.PAUSED
        };
    }

    movehome(){
        return this.props.history.push('/home')
    }
    componentWillReceiveProps(nextProps) {
        var that = this;
        if (nextProps.media) {
            this.setState({
                Up: nextProps.media.Up,
                Down: nextProps.media.Down,
                Right: nextProps.media.Right,
                Left: nextProps.media.Left,
                message: nextProps.media.message
            }, () => {
            })
        }
        
        if(nextProps.UserList.data) {
            this.setState({
                usersList: nextProps.UserList.data
            }, () => {
                if(this.state.usersList.length === 0 || this.state.usersList[0].uid !== this.props.user.uid) {
                    if (enteredd === 1){
                        window.location.reload();
                }
                }
              if(this.state.usersList.length > 0 && this.state.usersList[0].uid === this.props.user.uid) {
                enteredd = 1
                msgref.on("value", function(snapshot) {
                    if (snapshot.val() == '1') {
                        console.log(snapshot.val());
                        console.log('very success');
                        clearTimeout(timerr1);
                    clearTimeout(timerr1_1);
                    clearTimeout(timerr);
                    clearTimeout(timerr_1);
                    clearTimeout(congotimerr);
                    document.getElementById("stt1").style.display = "none";
                    document.getElementById("stt2").style.display = "none";
                    document.getElementById("stt3").style.display = "none";
                    document.getElementById("stt4").style.display = "none";
                    document.getElementById("stt5").style.display = "none";
                    document.getElementById("restart-button").style.display = "block";
                    document.getElementById("exit-button").style.display = "block";
                    document.getElementById("sad").style.display = "none";
                    document.getElementById("congoo").style.display = "block";
                    that.win();
                    that.setState({ win_playStatus: Sound.status.PLAYING })
                    sad_stop = setTimeout(function sad_pause() {
                    that.setState({ win_playStatus: Sound.status.PAUSED })
                    }, 8000)
                    firebase.database().ref('machine1/').update({
                        prize: '0'
                      });
            
                    congokick = "1";
                    congotimerr = setTimeout(function exitt() {
                        console.log("exit time")
                        var userin = firebase.auth().currentUser;
                        var database = firebase.database();
                        if (congokick == '1'){
                        const gameQueue = database.ref(`queue/${userin.uid}`);    
                        clearTimeout(timerr1);
                        console.log('kicked by congo')
                        gameQueue.remove();
			inientry = 0;
document.getElementById("stt1").style.display = "none";
                        document.getElementById("stt2").style.display = "none";
                        document.getElementById("stt3").style.display = "none";
                        document.getElementById("stt4").style.display = "none";
                        document.getElementById("stt5").style.display = "none";
                        // document.getElementById("stt6").style.display = "none";
                        document.getElementById("congoo").style.display = "none";
                        document.getElementById("sad").style.display = "none";
                        document.getElementById("restart-button").style.display = "none";
                        document.getElementById("exit-button").style.display = "block";
                        document.getElementById("start-button").style.display = "block";
                        document.getElementById("welcomemsg").style.display = "none";
                       // window.location.href = "/home";
                        console.log('it works?')
                        console.log(userin.uid);
                        console.log('id print');
                        }
                      }, 10000);
                    }
                
                    else {
                        // console.log(snapshot.val())
                        console.log('not success');
                    }
                  }, function (errorObject) {
                    console.log("The read failed: " + errorObject.code);
                  });


                  // Game begins
                if (inientry === 0){
                    var userinn = firebase.auth().currentUser;
        var database = firebase.database();
        firebase.database().ref('machine1User').update({
            uid: userinn.uid,
          });
        firebase.database().ref('gameAction/').update({
            Start: 1,
          });
newtime = setTimeout(function exitt() {


firebase.database().ref('gameAction/').update({
            Start: 0,
          });
},1500);
                document.getElementById("stt1").style.display = "block";
                document.getElementById("stt2").style.display = "block";
                document.getElementById("stt3").style.display = "block";
                document.getElementById("stt4").style.display = "block";
                document.getElementById("stt5").style.display = "block";
                // document.getElementById("stt6").style.display = "block";
                document.getElementById("start-button").style.display = "none";
                document.getElementById("exit-button").style.display = "none";
                document.getElementById("restart-button").style.display = "none";
                document.getElementById("congoo").style.display = "none";
                document.getElementById("sad").style.display = "none";
                document.getElementById("welcomemsg").style.display = "none";
                inientry = 2;

                timerr1_1 = setTimeout(function check() {
                    console.log("restart entry");
                    document.getElementById("stt1").style.display = "none";
                    document.getElementById("stt2").style.display = "none";
                    document.getElementById("stt3").style.display = "none";
                    document.getElementById("stt4").style.display = "none";
                    document.getElementById("stt5").style.display = "none";
                    // document.getElementById("stt6").style.display = "none";
                    document.getElementById("restart-button").style.display = "block";
                    document.getElementById("exit-button").style.display = "block";
                    document.getElementById("sad").style.display = "block";
                    that.loss();
                    that.setState({ loss_playStatus: Sound.status.PLAYING })
                    sad_stop = setTimeout(function sad_pause() {
                    that.setState({ loss_playStatus: Sound.status.PAUSED })
                    }, 2000)
                    document.getElementById("start-button").style.display = "none";
                    document.getElementById("welcomemsg").style.display = "none";
                    document.getElementById("congoo").style.display = "none";
        
                    test = "1";
                    //your code to be executed after 1 second
                    timerr_1 = setTimeout(function exitt() {
                        console.log("exit time")
                        var userin = firebase.auth().currentUser;
                        var database = firebase.database();
                        // var ref = database.ref('users/'+userin.uid+'/entry');
                        // database.ref('users/'+ userin.uid).update({
                        //     entry: '1'
                        //   });
                        // ref.on("value", function(snapshot) {
                        //     console.log(snapshot.val());
                        if (test === '1'){
                        const gameQueue = database.ref(`queue/${userin.uid}`);
            
                                //console.log(gameQueue);
                        gameQueue.remove();
                        console.log('kicked by first entry')
			inientry = 0;
document.getElementById("stt1").style.display = "none";
                        document.getElementById("stt2").style.display = "none";
                        document.getElementById("stt3").style.display = "none";
                        document.getElementById("stt4").style.display = "none";
                        document.getElementById("stt5").style.display = "none";
                        // document.getElementById("stt6").style.display = "none";
                        document.getElementById("congoo").style.display = "none";
                        document.getElementById("sad").style.display = "none";
                        document.getElementById("restart-button").style.display = "none";
                        document.getElementById("start-button").style.display = "block";
                        document.getElementById("exit-button").style.display = "block";
                        document.getElementById("welcomemsg").style.display = "none";
//                        window.location.href = "/home";
                        console.log('it works?')
                        console.log(userin.uid);
                        console.log('id print');
                        }
                      }, 5000);
                  }, 26000);
                  
                  clearTimeout(timerr);
                  clearTimeout(timerr_1);
              }
            }

            })
        }
        //  if(nextProps.UserList.data.length === 0 && this.props.UserList.data.length === 0) {
        //      return this.props.history.push('/home')
        //  }
        
        // if(nextProps.UserList.data.length > 0 && this.props.user.uid !== nextProps.UserList.data[0].uid) {
        //     return this.props.history.push('/home')
        // }

        
    }
    componentDidMount() {

        this.props.getUsersList()
        console.log(this.state.usersList)
        this.props.getButtonState()
        this.playcount();
        document.getElementById("stt1").style.display = "none";
        document.getElementById("stt2").style.display = "none";
        document.getElementById("stt3").style.display = "none";
        document.getElementById("stt4").style.display = "none";
        document.getElementById("stt5").style.display = "none";
        // document.getElementById("stt6").style.display = "none";
        document.getElementById("congoo").style.display = "none";
        document.getElementById("sad").style.display = "none";
        document.getElementById("restart-button").style.display = "none";
        document.getElementById("exit-button").style.display = "block";
        document.getElementById("cam2").style.display = "none";
        document.getElementById("soundoff").style.display = "block";
        document.getElementById("soundonn").style.display = "none";
        document.getElementById("start-button").style.display = "block";
        document.getElementById("welcomemsg").style.display = "none";
        // debugger
        // console.log ('len is ', this.props.UserList.data.length)
        // if(this.props.UserList.data.length === 0) {
        //     this.props.getUsersList()
        //     return this.props.history.push('/home')
        // }


    }
    drop_mouseUp = (event) => {
        // this.setState({ drop_playStatus: Sound.status.PAUSED })
        // this.setState({ a_position: 0 })
        console.log("drop music stop")
        console.log('on mouse up event -> ', event.target.name)
        this.setState({
        buttons:{[event.target.name]: 0}
        }, function() {
            
            this.props.changeStateOfButton(this.props.user, this.state.buttons)
        })
    }
    nav_mouseUp = (event) => {
        this.setState({ nav_playStatus: Sound.status.PAUSED })
        this.setState({ b_position: 0 })
        console.log('on mouse up event -> ', event.target.name)
        this.setState({
        buttons:{[event.target.name]: 0}
        }, function() {
            
            this.props.changeStateOfButton(this.props.user, this.state.buttons)
        })
    }
    drop_mouseDown = (event) => {
        this.setState({ drop_playStatus: Sound.status.PLAYING })
        console.log('on mouse down event -> ', event.target.name)
        console.log("drop music play")
        this.setState({
            buttons:{[event.target.name]: 1}
        }, function() {
            console.log('sending state ->', this.state)
            this.props.changeStateOfButton(this.props.user, this.state.buttons)
        })

    }
    nav_mouseDown = (event) => {
        this.setState({ nav_playStatus: Sound.status.PLAYING })
        console.log('on mouse down event -> ', event.target.name)
        console.log("drop music stop")
        this.setState({
            buttons:{[event.target.name]: 1}
        }, function() {
            console.log('sending state ->', this.state)
            this.props.changeStateOfButton(this.props.user, this.state.buttons)
        })

    }
    drop_onMouseOut = (event) => {
        // this.setState({ drop_playStatus: Sound.status.PAUSED })
        // this.setState({ a_position: 0 })
        console.log('on mouse out event -> ', event.target.name)
        this.setState({
            buttons:{[event.target.name]: 0}
        }, function() {
            this.props.changeStateOfButton(this.props.user, this.state.buttons)
        })
    }
    nav_onMouseOut = (event) => {
        this.setState({ nav_playStatus: Sound.status.PAUSED })
        this.setState({ b_position: 0 })
        console.log('on mouse out event -> ', event.target.name)
        this.setState({
            buttons:{[event.target.name]: 0}
        }, function() {
            this.props.changeStateOfButton(this.props.user, this.state.buttons)
        })
    }
    handleToggle = () => {
        this.setState({
            toggle: !this.state.toggle
        }, function() {
            
            this.props.changeStateOfButton(this.props.user, this.state.buttons)
        })
    }
    leaveRoom = () => {
        clearTimeout(timerr1);
        clearTimeout(timerr);
        clearTimeout(timerr_1);
        clearTimeout(timerr1_1);
        this.props.leaveGameRoom(this.props.user)
        return this.props.history.push('/home/game-zone')
    }

    changeview = () => {
        if (camm === 1){
            console.log(camm)
            document.getElementById("cam1").style.display = "none";
            document.getElementById("cam2").style.display = "block";
            camm = 2;
        }
        else if (camm === 2){
            console.log(camm)

            document.getElementById("cam1").style.display = "block";
            document.getElementById("cam2").style.display = "none";
            camm = 1;
        }
    }

    muteornot = () => {
        if (soundd === 2){
            console.log(soundd)
            this.setState({ playStatus: Sound.status.PAUSED })
            document.getElementById("soundonn").style.display = "none";
            document.getElementById("soundoff").style.display = "block";
            soundd = 1;
        }
        else if (soundd === 1){
            console.log(soundd)
            this.setState({ playStatus: Sound.status.PLAYING })
            document.getElementById("soundonn").style.display = "block";
            document.getElementById("soundoff").style.display = "none";
            soundd = 2;
        }
    }

    playcount = () =>{
        var that = this;
    var userId = firebase.auth().currentUser.uid;
    firebase.database().ref('/users/' + userId).on('value', function(snapshot) {
    console.log("number of times game played:" + snapshot.val().total_played)
    that.setState({
        totalplay: snapshot.val().total_played,
        thisplay: snapshot.val().game1_play,
        thiswin: snapshot.val().game1_win,
        thisloss: snapshot.val().game1_loss
    })
    // this.state.totalplay = snapshot.val().total_played;
    // this.state.thisplay = snapshot.val().game1_play;
    // this.tate.thisloss = snapshot.val().game1_loss;
    // this.state.thiswin = snapshot.val().game1_win;  
});
    }
    playadd = () =>{
    var userId = firebase.auth().currentUser.uid;
    firebase.database().ref('/users/' + userId).once('value').then(function(snapshot) {
    console.log("number of times game played:" + snapshot.val().total_played)
      var count;
      count = snapshot.val().total_played + 1;
      console.log('PLAYEDDDD COUNTTTTTTTTTT:'+count);
      firebase.database().ref('users/' + userId).update({
      total_played: count
  });
});
firebase.database().ref('/users/' + userId).once('value').then(function(snapshot) {
    console.log("number of times game played:" + snapshot.val().game1_play)
      var count;
      count = snapshot.val().game1_play + 1;
      console.log('PLAYEDDDD COUNTTTTTTTTTT:'+count);
      firebase.database().ref('users/' + userId).update({
      game1_play: count
  });
});        
    }

    loss = () => {
    firebase.database().ref('machine1/').update({
        loss: '1'
    });
    setTimeout(function(){
    firebase.database().ref('machine1/').update({
            loss: '0'
        });
    }, 4000);
    var userId = firebase.auth().currentUser.uid;
    firebase.database().ref('/users/' + userId).once('value').then(function(snapshot) {
    console.log("number of times game played:" + snapshot.val().total_loss)
      var count;
      count = snapshot.val().total_loss + 1;
      console.log('PLAYEDDDD COUNTTTTTTTTTT:'+count);
      firebase.database().ref('users/' + userId).update({
      total_loss: count
  });
});
firebase.database().ref('/users/' + userId).once('value').then(function(snapshot) {
    console.log("number of times game played:" + snapshot.val().game1_loss)
      var count;
      count = snapshot.val().game1_loss + 1;
      console.log('PLAYEDDDD COUNTTTTTTTTTT:'+count);
      firebase.database().ref('users/' + userId).update({
      game1_loss: count
  });
});   

    }

    win = () => {
    var userId = firebase.auth().currentUser.uid;
    firebase.database().ref('/users/' + userId).once('value').then(function(snapshot) {
    console.log("number of times game played:" + snapshot.val().total_win)
      var count;
      count = snapshot.val().total_win + 1;
      console.log('PLAYEDDDD COUNTTTTTTTTTT:'+count);
      firebase.database().ref('users/' + userId).update({
      total_win: count
  });
});
firebase.database().ref('/users/' + userId).once('value').then(function(snapshot) {
    console.log("number of times game played:" + snapshot.val().game1_win)
      var count;
      count = snapshot.val().game1_win + 1;
      console.log('PLAYEDDDD COUNTTTTTTTTTT:'+count);
      firebase.database().ref('users/' + userId).update({
      game1_win: count
  });
});   
        
    }


    entertogame = () => {
        let that = this;
        if(this.props.user.balance < 2 ) {
            clearTimeout(timerr1);
            clearTimeout(timerr);
            clearTimeout(congotimerr);
            alert ("Not enough credit")
            return this.props.history.push('/home')
        }
        that.setState({ start_playStatus: Sound.status.PLAYING })
                    sad_stop = setTimeout(function sad_pause() {
                    that.setState({ start_playStatus: Sound.status.PAUSED })
                    }, 1000)
        this.props.updateUserBalance(this.props.user.uid,this.props.user.balance - 150);
        this.props.addUserToGame(this.props.user);
        this.playadd();

        document.getElementById("start-button").style.display = "none";
        document.getElementById("welcomemsg").style.display = "block";
    }
    startgamee = () => {
        let that = this;
        congokick = '0'
        clearTimeout(congotimerr);
        if(this.props.user.balance < 2 ) {
            clearTimeout(timerr_1);
            clearTimeout(congotimerr);
            clearTimeout(timerr);
            clearTimeout(timerr1);
            clearTimeout(timerr1_1);
            alert ("Not enough credit")
            return this.props.history.push('/home')
        }
        that.setState({ start_playStatus: Sound.status.PLAYING })
                    sad_stop = setTimeout(function sad_pause() {
                    that.setState({ start_playStatus: Sound.status.PAUSED })
                    }, 1000)
        clearTimeout(congotimerr);
        console.log('congo timed out')
        clearTimeout(timerr);
        clearTimeout(timerr_1);

        console.log(test);
        this.props.updateUserBalance(this.props.user.uid,this.props.user.balance - 150);
        this.playadd();
        console.log("credit deducted")
        firebase.database().ref('gameAction/').update({
            Start: 1,
          });
newtime = setTimeout(function exitt() {


firebase.database().ref('gameAction/').update({
            Start: 0,
          });
},1500);
        document.getElementById("stt1").style.display = "block";
        document.getElementById("stt2").style.display = "block";
        document.getElementById("stt3").style.display = "block";
        document.getElementById("stt4").style.display = "block";
        document.getElementById("stt5").style.display = "block";
        // document.getElementById("stt6").style.display = "block";
        document.getElementById("start-button").style.display = "none";
        document.getElementById("restart-button").style.display = "none";
        document.getElementById("congoo").style.display = "none";
        document.getElementById("sad").style.display = "none";
        document.getElementById("welcomemsg").style.display = "none";

        console.log("start game update");

        timerr1 = setTimeout(function check() {
            console.log("restart entry");
            document.getElementById("stt1").style.display = "none";
            document.getElementById("stt2").style.display = "none";
            document.getElementById("stt3").style.display = "none";
            document.getElementById("stt4").style.display = "none";
            document.getElementById("stt5").style.display = "none";
            // document.getElementById("stt6").style.display = "none";
            document.getElementById("restart-button").style.display = "block";
            document.getElementById("sad").style.display = "block";
            that.loss();
            that.setState({ loss_playStatus: Sound.status.PLAYING })
                    sad_stop = setTimeout(function sad_pause() {
                    that.setState({ loss_playStatus: Sound.status.PAUSED })
                    }, 2000)

            // var databasen = firebase.database();
            // var userine = firebase.auth().currentUser;
            // databasen.ref('users/'+ userin.uid).update({
            //   entry: '1'
            // });

            test = "1";
            //your code to be executed after 1 second
            timerr = setTimeout(function exitt() {
                console.log("exit time")
                var userin = firebase.auth().currentUser;
                var database = firebase.database();
                // var ref = database.ref('users/'+userin.uid+'/entry');
                // database.ref('users/'+ userin.uid).update({
                //     entry: '1'
                //   });
                // ref.on("value", function(snapshot) {
                //     console.log(snapshot.val());
                if (test === '1'){
                const gameQueue = database.ref(`queue/${userin.uid}`);
    
                        //console.log(gameQueue);
                clearTimeout(timerr1);
                gameQueue.remove();
                console.log('regular kick')
                window.location.href = "/home";
                        //return this.push('/home');
                console.log('it works?')
                //         //console.log(userin)
                //         //leaveGameRoom(userin);                    
                //     }
                //     }, function (errorObject) {
                //     console.log("The read failed: " + errorObject.code);
                // });
                console.log(userin.uid);
                console.log('id print');
                }
                //your code to be executed after 1 second
              }, 5000);
          }, 26000);
          
          
          clearTimeout(timerr);
    }

    // componentWillReceiveProps(nextProps) {
    //     if(nextProps.media.redirect) this.props.history.push('/home')
    // }
    render () {
        return (
            
            <div style = { styles.container }> 
            <Sound
              url={musicc}
              playStatus={this.state.playStatus}
              position={this.state.position}
              volume={this.state.volume}
              playbackRate={this.state.playbackRate}
              loop={this.state.loop}
              onLoading={({ bytesLoaded, bytesTotal }) => console.log(`${bytesLoaded / bytesTotal * 100}% loaded`)}
              onLoad={() => console.log('Loaded')}
              onPlaying={({ position }) => this.setState({ position })}
              onPause={() => console.log('Paused')}
              onResume={() => console.log('Resumed')}
              onStop={() => console.log('Stopped')}
              onFinishedPlaying={() => console.log("replaying")}
            />
            <Sound
              url={dropmusic}
              playStatus={this.state.drop_playStatus}
              position={this.state.a_position}
              volume={this.state.a_volume}
              playbackRate={this.state.a_playbackRate}
              loop={false}
              onLoading={({ bytesLoaded, bytesTotal }) => console.log(`${bytesLoaded / bytesTotal * 100}% loaded`)}
              onLoad={() => console.log('Loaded')}
              onPlaying={({ a_position }) => this.setState({ a_position })}
              onPause={() => console.log('Paused')}
              onResume={() => console.log('Resumed')}
              onStop={() => console.log('Stopped')}
              onFinishedPlaying={() => this.setState({ drop_playStatus: Sound.status.PAUSED })}
            />
            <Sound
              url={navmusic}
              playStatus={this.state.nav_playStatus}
              position={this.state.b_position}
              volume={this.state.b_volume}
              playbackRate={this.state.b_playbackRate}
              loop={this.state.b_loop}
              onLoading={({ bytesLoaded, bytesTotal }) => console.log(`${bytesLoaded / bytesTotal * 100}% loaded`)}
              onLoad={() => console.log('Loaded')}
              onPlaying={({ b_position }) => this.setState({ b_position })}
              onPause={() => console.log('Paused')}
              onResume={() => console.log('Resumed')}
              onStop={() => console.log('Stopped')}
              onFinishedPlaying={() => console.log("replaying")}
            />
            <Sound
              url={winmusic}
              playStatus={this.state.win_playStatus}
              position={this.state.c_position}
              volume={this.state.c_volume}
              playbackRate={this.state.c_playbackRate}
              loop={this.state.c_loop}
              onLoading={({ bytesLoaded, bytesTotal }) => console.log(`${bytesLoaded / bytesTotal * 100}% loaded`)}
              onLoad={() => console.log('Loaded')}
              onPlaying={({ c_position }) => this.setState({ c_position })}
              onPause={() => console.log('Paused')}
              onResume={() => console.log('Resumed')}
              onStop={() => console.log('Stopped')}
              onFinishedPlaying={() => console.log("replaying")}
            />
            <Sound
              url={startmusic}
              playStatus={this.state.start_playStatus}
              position={this.state.c_position}
              volume={this.state.c_volume}
              playbackRate={this.state.c_playbackRate}
              loop={this.state.c_loop}
              onLoading={({ bytesLoaded, bytesTotal }) => console.log(`${bytesLoaded / bytesTotal * 100}% loaded`)}
              onLoad={() => console.log('Loaded')}
              onPlaying={({ c_position }) => this.setState({ c_position })}
              onPause={() => console.log('Paused')}
              onResume={() => console.log('Resumed')}
              onStop={() => console.log('Stopped')}
              onFinishedPlaying={() => console.log("replaying")}
            />
            <Sound
              url={lossmusic}
              playStatus={this.state.loss_playStatus}
              position={this.state.c_position}
              volume={this.state.c_volume}
              playbackRate={this.state.c_playbackRate}
              loop={this.state.c_loop}
              onLoading={({ bytesLoaded, bytesTotal }) => console.log(`${bytesLoaded / bytesTotal * 100}% loaded`)}
              onLoad={() => console.log('Loaded')}
              onPlaying={({ c_position }) => this.setState({ c_position })}
              onPause={() => console.log('Paused')}
              onResume={() => console.log('Resumed')}
              onStop={() => console.log('Stopped')}
              onFinishedPlaying={() => console.log("replaying")}
            />
            {/* OLDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDD
                <div>
                    <Iframe url="http://bigchoice1.ddns.net:8086/index.html"
                        width="650px"
                        height="610px"
                        id="myId"
                        className="myClassname"
                        display="initial"
                        position="relative" />

                </div>
                <div style = {{marginTop: 20, marginBottom: 20}}><h3>{this.props.media ? this.props.media.message : null}</h3></div>
                <div style = { styles.div }>
                    
                <div className="dropzone">
                        
                </div>
                </div>
                <div style = { Object.assign({}, styles.div, styles.inputDiv) }>
                <input id="stt1" type="image" alt="UP" src={up} className = "button pulse-shrink" style= {styles.button} name = {'Up'} value = "Up" onMouseDown = {this.mouseDown} onMouseUp = {this.mouseUp} onMouseOut = {this.onMouseOut} />
                <input id="stt2" type="image" alt="DOWN" src={down} style= {styles.button} className = "button pulse-shrink" name = {'Down'} value = "Down" onMouseDown = {this.mouseDown} onMouseUp = {this.mouseUp} onMouseOut = {this.onMouseOut} />
                    <input id="stt3" type="image" alt="Left" src={left} style= {styles.button} name = {'Left'} value = "Left"  className = "button pulse-shrink"  onMouseDown = {this.mouseDown} onMouseUp = {this.mouseUp} onMouseOut = {this.onMouseOut}/>
                    <input id="stt4" type="image" alt="RIGHT" src={right} className = "button pulse-shrink" style= {styles.button} name = {'Right'} value = "Right" onMouseDown = {this.mouseDown} onMouseUp = {this.mouseUp} onMouseOut = {this.onMouseOut} />
                    <input id="stt5" type="button" style= {Object.assign({},styles.button, {backgroundColor: 'green'}) }  className = "button pulse-shrink" name = {'DropClaw'} value = "DropClaw" onMouseDown = {this.mouseDown} onMouseUp = {this.mouseUp} onMouseOut = {this.onMouseOut}  />
                    <input id="stt6" type="button" style= {Object.assign({},styles.button, {backgroundColor: 'green'}) } name = {'InsertCoin'}  className = "button pulse-shrink" value = "InsertCoin" onMouseDown = {this.mouseDown} onMouseUp = {this.mouseUp} onMouseOut = {this.onMouseOut}  />
                    <input id="start-button" type="button" style= {Object.assign({},styles.button, {backgroundColor: 'green'}) } name = {'Start'} value = "Start" onClick = {this.startgamee} onMouseDown = {this.mouseDown} onMouseUp = {this.mouseup} onMouseOut = {this.onMouseOut}  className = "button pulse-shrink" />
                    <input id="restart-button" type="button" style= {Object.assign({},styles.button, {backgroundColor: 'green'}) } name = {'Start'} value = "Restart Game" onClick = {this.startgamee} onMouseDown = {this.mouseDown} onMouseUp = {this.mouseup} onMouseOut = {this.onMouseOut} className = "button pulse-shrink" />

                </div>
                <div>
                    <div>
                        <input type="button" style= {Object.assign({},styles.button, {backgroundColor: 'red'}) } value = {"Leave Game room"} onClick = {this.leaveRoom}/>
                    </div>
                </div>

                OLDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDD */}
                {/* CHECK ------------------------------------------------------ */}
                <div class="container-fluid">
	<div class="row">
		<div class="col-md-8">
			<div class="row">
				<div class="col-md-12">
                <div id = 'cam1'>
                    <Iframe url="http://bigchoice1.ddns.net:8082/index.html"
                        width="650px"
                        height="610px"
                        id="myId"
                        className="myClassname"
                        display="initial"
                        position="relative" />

                </div>
                <div id = 'cam2'>
                    <Iframe url="http://bigchoice1.ddns.net:8086/index.html"
                        width="650px"
                        height="610px"
                        id="myId"
                        className="myClassname"
                        display="initial"
                        position="relative" />

                </div>
				</div>
			</div>
		</div>
		<div class="col-md-4">
			<div class="row">
				<div class="col-md-12">
                <div><h4>Total Games Played: {this.state.totalplay}</h4></div>
                <div><h4>Night Life Play: {this.state.thisplay}</h4></div>
                <div><h4>Night Life Wins: {this.state.thiswin}</h4></div>
                <div><h4>Night Life Loss: {this.state.thisloss}</h4></div>
					<h3>
						Users On this Machine
					</h3>
                    {/* USERRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRR */}
                    {
                    this.state.usersList.length > 0 ? this.state.usersList.map((user, index) => {
                        
                        return (
                            <div class = "row">
                            <div style = {styles1.flexDisplay}>
                                <div class="col-md-8">
                                <div id = 'onee'>
                                    {user.displayName}
                                </div>
                                </div>
                                <div class="col-md-4">
                                <div id = 'twoo'>
                                    <img src = {user.photoURL} style = {styles1.img}/>
                                </div> 
                                </div>                               
                            </div>
                            </div>
                            
                        )
                    })
                : <div><h4>User List is empty</h4></div>}
                    {/* USERRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRR */}
				</div>
			</div>
			<div class="row">
				<div class="col-md-12">
                    <input id="camera" type="image" alt="Camera" src={camera} style= {styles.button} name = {'Canera'} value = "Camera"  className = "button pulse-shrink"  onClick = {this.changeview}/>
                    <input id="soundonn" type="image" alt="sound" src={soundon} style= {styles.button} name = {'Sound'} value = "Sound"  className = "button pulse-shrink"  onClick = {this.muteornot}/>
                    <input id="soundoff" type="image" alt="sound" src={soundoff} style= {styles.button} name = {'Sound'} value = "Sound"  className = "button pulse-shrink"  onClick = {this.muteornot}/>
				</div>
			</div>
		</div>
	</div>
</div>
                {/* CHECK ------------------------------------------------------ */}
                <div class="row">
				<div class="col-md-12">
					<div class="row">
						<div class="col-md-6">
                        
                        <input id="stt1" type="image" alt="UP" src={up} className = "button pulse-shrink" style= {Object.assign({},styles.button, {marginLeft: 83}) } name = {'Up'} value = "Up" onMouseDown = {this.nav_mouseDown} onMouseUp = {this.nav_mouseUp} onMouseOut = {this.nav_onMouseOut} />
						</div>
						<div class="col-md-6">
						</div>
					</div>
					<div class="row">
						<div class="col-md-6">
							<div class="row">
								<div class="col-md-6">
                                <input id="stt3" type="image" alt="Left" src={left} style= {styles.button} name = {'Left'} value = "Left"  className = "button pulse-shrink"  onMouseDown = {this.nav_mouseDown} onMouseUp = {this.nav_mouseUp} onMouseOut = {this.nav_onMouseOut}/>
								</div>
								<div class="col-md-6">
                                <input id="stt4" type="image" alt="RIGHT" src={right} className = "button pulse-shrink" style= {Object.assign({},styles.button, {marginLeft: 10}) } name = {'Right'} value = "Right" onMouseDown = {this.nav_mouseDown} onMouseUp = {this.nav_mouseUp} onMouseOut = {this.nav_onMouseOut} />
								</div>
							</div>
						</div>
						<div class="col-md-6">
                        <input id="stt5" type="image" alt="GRAB" src={grab} style= {styles.button} className = "button pulse-shrink" name = {'DropClaw'} value = "DropClaw" onMouseDown = {this.drop_mouseDown} onMouseUp = {this.drop_mouseUp} onMouseOut = {this.drop_onMouseOut} />
						</div>
					</div>
					<div class="row">
						<div class="col-md-6">
                        <input id="stt2" type="image" alt="DOWN" src={down} style= {Object.assign({},styles.button, {marginLeft: 83, marginBottom: 20}) } className = "button pulse-shrink" name = {'Down'} value = "Down" onMouseDown = {this.nav_mouseDown} onMouseUp = {this.nav_mouseUp} onMouseOut = {this.nav_onMouseOut} />
						</div>
						<div class="col-md-6">
						</div>
					</div>
				</div>
			</div>
            <center>
                <div>
                    <div>
                    <input id="start-button" type="image" alt="START" src={start} style= {Object.assign({},styles.button) } name = {'Start'} value = "Start" onClick = {this.entertogame} className = "button pulse-shrink" />
                        <div id='welcomemsg'> <h3>Welcome to Game Room, Please wait for your turn</h3> </div>
                        <div id = "sad">
                        <div style = {{marginTop: 20, marginBottom: 20}}><h3>Sadly No Prize</h3></div>
                        <input id="restart-button" type="image" alt="re-start" src={restart} style= {styles.button} name = {'Start'} value = "Play Again?" onClick = {this.startgamee} onMouseDown = {this.mouseDown} onMouseUp = {this.mouseup} onMouseOut = {this.onMouseOut} className = "button pulse-shrink" />
                        </div>
                        <div id = "congoo">
                        <div style = {{marginTop: 20, marginBottom: 20}}><h3>Congrats, You've won a prize</h3></div>
                        <input id="restart-button" type="image" alt="re-start" src={restart} style= {styles.button} name = {'Start'} value = "Play Again?" onClick = {this.startgamee} onMouseDown = {this.mouseDown} onMouseUp = {this.mouseup} onMouseOut = {this.onMouseOut} className = "button pulse-shrink" />
                        </div>
                    </div>
                    <div>
                        <input id="exit-button" type="image" alt="exit" src={exit} style= {Object.assign({},styles.button, {marginBottom: 20}) } value = {"Leave Game room"} onClick = {this.leaveRoom}/>
                    </div>
                </div>
                </center>
            </div>
        );
    }
}

function mapStateToProps ({socialSignIn, mediaReducer, UserList}) {
    return {
        user: socialSignIn,
        media: mediaReducer,
        UserList: UserList
    };
};

const styles = {
    container: {
        paddingTop: '30px',
        alignItems: 'center',
        display: 'flex',
        flexGrow: '1',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    image: {
        position: 'fixed',
        height: '150px'
    },
    div: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    inputDiv: {
        paddingTop: '20px'
    },
    inputField: {
        textAlign:'center',
        width: '400px'
    },
    button: {
        backgroundColor: 'rgba(41, 58, 42, 0)',
        border: 'none',
        color: 'white',
        padding: '15px 32px',
        textAlign: 'center',
        textDecoration: 'none',
        display: 'inline-block',
        fontSize: '16px',
        margin: '4px 2px',
        cursor: 'pointer'
    },
    uploadDiv: {
        'paddingTop': '50%',
        position: 'relative',
        width: '400px',
        border: 'dotted',
        borderRadius: '6%',
        height: '400px',
    }
}

const styles1 = {
    flexDisplay: {
        display: 'flex',
        marginTop: 5,
        marginBotton: 5,
        fontWeight: 'bold'
    },
    subContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        marginTop: '30px'
    },
    img: {
        marginTop: 5,
        marginBotton: 5,
        marginLeft: 5,
        height: '50px',
        width: '50px'
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        marginLeft: '50px'
    }
    
}

export default connect(mapStateToProps, { changeStateOfButton,leaveGameRoom,updateUserBalance, getUsersList, getButtonState,updateUserEntryInGameRoom,addUserToGame})(MediaUpload);

