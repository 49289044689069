import { ERROR_FETCHING_USERS,USERS_FETCHED, FETCHING_USER_LIST} from '../actions/game-zone-action';

const INITIAL_STATE = { loading: true, fetched: false, error: false, data: []};

export default function (state = {} , action) {
    switch (action.type) {
        case FETCHING_USER_LIST:
            INITIAL_STATE.loading = true;
            INITIAL_STATE.fetched = false;
            INITIAL_STATE.error = false
            INITIAL_STATE.data = []
            return state = {...INITIAL_STATE};
        case USERS_FETCHED:
            INITIAL_STATE.loading = true;
            INITIAL_STATE.fetched = true;
            INITIAL_STATE.error = false
            INITIAL_STATE.data = action.payload
            return state = {...INITIAL_STATE};
        case ERROR_FETCHING_USERS:
            INITIAL_STATE.loading = false;
            INITIAL_STATE.fetched = false;
            INITIAL_STATE.error = true
            INITIAL_STATE.data = []
            return state = {...INITIAL_STATE};
        default:
            return state;
    }
}