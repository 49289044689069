import React, { Component } from "react";
import { connect } from "react-redux";
import { socialSignIn, authStateChanged, signUpWithEmailPassword, signInWithEmailPassword} from "../actions/social-signin-action";
import googleIcon from '../assets/icon-google.png'
import { getUsersList } from '../actions/game-zone-action';
import { getButtonState } from '../actions/media-action';
import './login-css.css';
import Modal from "react-responsive-modal";
import logo from '../assets/realclaw.png';

class Login extends Component {
  
  state = {
    open: false,
    open1: false
  };

  onOpenModal = () => {
    this.setState({ open: true, open1: false });
  };

  onCloseModal = () => {
    this.setState({ open: false, open1: false });
  };

  onOpenModal1 = () => {
    this.setState({ open1: true, open: false });
    console.log("open1 open")
    console.log(this.state.open1)
  };

  onCloseModal1 = () => {
    this.setState({ open1: false, open: false });
    console.log("open1 close")
  };

componentWillReceiveProps(props) {
  if (this.props.authStateChanged){
  }

}
  

  constructor(props) {
    super(props)
    this.state = {
      email: '',
      password: '',
      chemail: '',
      signupemail: '',
      signuppass: '',
      signuppasscheck: ''
    }
  }
  handleInputChange = (event) => {
    this.setState({
      [event.target.name]: [event.target.value]
    }, function() {
    })
  }
  signInUser = () => {
    this.props.signInWithEmailPassword(this.state.email[0], this.state.password[0])
    console.log(this.props.authStateChanged)
  }

  movesignup = () => {
    window.location.href = "/signup";  
  }

  moveforgetpass = () => {
    window.location.href = "/forget";
  }
  componentDidMount() {
    this.props.getButtonState()
    this.props.getUsersList()
  }

  socialSigninHandler = event => {
    const signInType = event.target.name;
    switch (signInType) {
      case "facebook":
        this.props.socialSignIn (signInType);
        break;
      case "google":
        this.props.socialSignIn (signInType);
        break;
      default:
        break;
    }
  };

  render() {
    const { open } = this.state;
    return (
      
         <div className="wrapper">
  <div className="container">
  <img src= {logo}></img>
    <form autocomplete="off" action="">
      <div className="group">
        <label for="email">Email:</label>
        <input type="email" id="email" name="email" placeholder="name@email.com" style = {{border: 'solid 1px'}} onChange = {this.handleInputChange}/>
      </div>
      <div className="group">
        <label for="password">Password:</label>
        <input id="password" type="password" name="password" placeholder="password" style = {{border: 'solid 1px'}} onChange = {this.handleInputChange}/>
      </div>
      <input type="button" name = "Sign In" value = "Log In" onClick = {this.signInUser}/>
      <input type="button" name = "Sign up" value = "Create Account" onClick = {this.movesignup} style = {{marginLeft: 5}}/>
      <input type="button" name = "Sign up" value = "Forgot Password" onClick = {this.moveforgetpass} style = {{marginLeft: 5}}/>
    </form>
    <button  className="btn-face m-b-20 login100-form-btn" name ="facebook" onClick = { this.socialSigninHandler }>
						<div><i className="fab fa-facebook-square"></i></div>
            <div>Facebook</div>
			 		</button>
    
           <button className="btn-google m-b-20 login100-form-btn flex" name="google" onClick = { this.socialSigninHandler }>
			 			<div>
             <img src= { googleIcon } alt="GOOGLE"/>
             </div>
             Google
			 		</button>
    
      <Modal open={open} onClose={this.onCloseModal}>
        <form autoComplete="off">
          <h2 style = {{marginTop: 15}}>Forgot Password?</h2>
          <label  for="resetemail">Email:</label>
          <input  type="text" class="testt"  style = {{marginTop: 15}} id="chemail" name="chemail" placeholder="Enter Email" style = {{border: 'solid 1px'}} onChange = {this.handleInputChange}/>
          <input type="button" style = {{marginTop: 15}} name = "Sign In" value = "Reset" onClick = {this.resetpassword}/>
          </form>
        </Modal>

        <Modal open={this.state.open1} onClose={this.onCloseModal1}>
        <form autoComplete="off">
          <h2 style = {{marginTop: 15}}>Create Account</h2>
          <label  for="resetemail">Email:</label>
          <input  type="text" class="testt"  style = {{marginTop: 15}} id="signupemail" name="signupemail" placeholder="Enter Email" style = {{border: 'solid 1px'}} onChange = {this.handleInputChange}/>
          <label  for="resetemail">Password:</label>
          <input  type="password" class="testt"  style = {{marginTop: 15}} id="signuppass" name="signuppass" placeholder="Password" style = {{border: 'solid 1px'}} onChange = {this.handleInputChange}/>
          <label  for="resetemail">Repeat Password:</label>
          <input  type="password" class="testt"  style = {{marginTop: 15}} id="signuppasscheck" name="signuppasscheck" placeholder="Repeat Password" style = {{border: 'solid 1px'}} onChange = {this.handleInputChange}/>
          <input type="button" style = {{marginTop: 15}} name = "Sign In" value = "Create Account" onClick = {this.signUpUser}/>
          </form>
        </Modal>
  </div>
  </div>

  
    );
  }
};

export default connect (null, { socialSignIn, authStateChanged,getUsersList,getButtonState, signUpWithEmailPassword, signInWithEmailPassword})(Login);
