import { ERROR_ADDING_USER, ADDING_USER_TO_GAME_ZONE, USER_ADDED_TO_GAME_ZONE } from '../actions/game-zone-action';

const INITIAL_STATE = { loading: true, userAdd: false, error: false};

export default function (state = {} , action) {
    switch (action.type) {
        case ADDING_USER_TO_GAME_ZONE:
            INITIAL_STATE.loading = true;
            INITIAL_STATE.userAdd = false;
            INITIAL_STATE.error = false
            return state = {...INITIAL_STATE};
        case USER_ADDED_TO_GAME_ZONE:
            INITIAL_STATE.loading = false;
            INITIAL_STATE.userAdd = true;
            INITIAL_STATE.error = false
            return state = {...INITIAL_STATE};
        case ERROR_ADDING_USER:
            INITIAL_STATE.loading = false;
            INITIAL_STATE.userAdd = false;
            INITIAL_STATE.error = true
            return state = {...INITIAL_STATE};
        default:
            return state;
    }
}