import React, {Component} from 'react'
import firebase from 'firebase';
import {connect} from 'react-redux'
import { Redirect } from 'react-router-dom'
const db = firebase.database();
var userin = firebase.auth().currentUser;
class GameZone extends Component {
    constructor(props) {
        super(props)
        this.state = {
            nightlife: [],
            lovegift: [],
            bigchoice: [],
            current: "Play History",
            fetch_status:"Fetching Data, Please Wait"
        }
    }

componentDidMount() {
  var userinn = firebase.auth().currentUser;
  console.log(userinn.uid)
let that = this;
var timezonee = Intl.DateTimeFormat().resolvedOptions().timeZone


// LOVE GIFTTTTTT

fetch("https://history.realclaw.com/game_plays/"+ userinn.uid+'/'+timezonee) // Call the fetch function passing the url of the API as a parameter
.then((resp2) => resp2.json())
.then(function(data2) {
  console.log(data2['hello'])
  var resss2 = data2['hello'];
  var data22 = [];
        // resss2.forEach(function(child2) {
        //   var split2 = child2['time'].split(".")
        //   console.log('i am child',split2[0])
        //   child2['time'] = split2[0]
        //   data22.push(child2);
        // });
        // console.log(data22)
          // data22 = data22.reverse();
  that.setState({
            lovegift: data2['hello'],
          })
    // Your code for handling the data you get from the API
})
.catch(function(error) {
  console.log(error)
    // This is where you run code if the server returns any errors
});

    console.log("helooooo")
          setTimeout(function(){
            that.setState({
            fetch_status: "Not Enough Data",
          })
        }, 5000);
}





    render() {
        return (
            <div style = {styles.container}>
        <div class="container-fluid">
        <div class="row">
        <div class="col-md-4">
</div>
        </div>
        <div class="row">
        <div class="col-md-4"></div>
        <div class="col-md-4">
                <center>
          <h3>{this.state.current}</h3>
        </center>
</div>
<div class="col-md-4"></div>
        </div>
  <div class="row">
    <div class="col-md-4">
      
        <div>
             </div>
  

    </div>
    <div class="col-md-4">
      
        <div>
             </div>
  <center>
{
    this.state.lovegift.length > 0 ?
            <div class = "row">
            <div style = {styles1.flexDisplay}>
                <div style = {{marginLeft:0}} class="col-md-8">
                    <center>Date and Time</center>
                </div> 
                <div style = {{marginLeft:5}}  class="col-md-2">
                 Machine
                </div>
                <div style = {{marginLeft:40}}  class="col-md-2">
                 Result
                </div>                               
            </div>
            </div>

    : <div><h5></h5></div>
  }
{
    this.state.lovegift.length > 0 ?this.state.lovegift.map((user2, index) => {
        
        return (
            <div class = "row">
            <div style = {styles1.flexDisplay}>
      
                <div class="col-md-6">
                    {user2.time}
                </div> 
                <div class="col-md-4">
                  {user2.machine}
                </div> 
                <div class="col-md-2">
                  {user2.result}
                </div>                               
            </div>
            </div>
            
        )
    })
    : <div><h5>{this.state.fetch_status}</h5></div>}
</center>

    </div>
    <div class="col-md-4">
      
        <div>
             </div>


    </div>
  </div>

</div>

            </div>
        )
    }
}

const styles1 = {
    flexDisplay: {
        display: 'flex',
        marginTop: 5,
        marginBotton: 5,
        fontWeight: 'bold'
    },
    subContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        marginTop: '30px'
    },
    img: {
        marginTop: 5,
        marginBotton: 5,
        marginLeft: 5,
        height: '50px',
        width: '50px'
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        marginLeft: '500px'
    }
    
}

const styles = {
    flexDisplay: {
        display: 'flex',
        marginTop: 5,
        marginBotton: 5,
        fontWeight: 'bold'
    },
    mediaObjects: {
        width: '300px'
    },
    subContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        marginTop: '30px'
    },
    img: {
        marginTop: 5,
        marginBotton: 5,
        marginLeft: 5,
        height: '50px',
        width: '50px'
    },
    container: {
        paddingTop: '30px',
        marginLeft: '100px',
        alignItems: 'center',
        display: 'flex',
        flexGrow: '1',
        flexDirection: 'column',
        justifyContent: 'center'
        
    }
    
}
const mapStateToProps = (state) => {
    return state;
}
export default connect(mapStateToProps)(GameZone)