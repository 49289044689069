import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios'
import {updateUserBalance} from '../actions/game-zone-action'
import {NotificationManager} from 'react-notifications';
import firebase from "firebase";
import './form-css.css';
import FileUploader from "react-firebase-file-uploader";

var userin
var database
var auth = firebase.auth();
var user = firebase.auth().currentUser;
console.log("hereemail")
// console.log(user.email)
class MyVideos extends Component {

    constructor(props) {
        super(props)
        this.state = {
            name: '',
            phone: '',
            address: '',
            newpass: '',
            repeatpass: '',
            curname: '',
            curphone: '',
            curaddress: '',
            city: '',
            state: '',
            zip: '',
            country: '',
            avatar: '',
            isUploading: false,
            progress: 0,
            avatarURL: ""
        }
    }
    reauthenticate = (currentPassword) => {
        var user = firebase.auth().currentUser;
        var cred = firebase.auth.EmailAuthProvider.credential(
            user.email, currentPassword);
        return user.reauthenticateWithCredential(cred);
      }
    componentDidMount(){
        // var curuser = firebase.auth().currentUser;
        userin = firebase.auth().currentUser;
        database = firebase.database();
        
    }
    handleChange = (event) => {
        this.setState({
            [event.target.name]: [event.target.value][0]
        })

    }
    resetpassword = () => {
        // console.log(this.state.email[0]);
        // console.log(this.state.password[0]);
        var user = firebase.auth().currentUser;
        console.log(user.email);
        auth.sendPasswordResetEmail(user.email).then(function() {
          console.log("good");
          alert("Password Reset Email Sent");
          // Email sent.
        }).catch(function(error) {
          console.log("bad");
          alert("Error with your Email");
          // An error happened.
        });
      }

handleUploadStart = () => this.setState({ isUploading: true, progress: 0 });
handleProgress = progress => this.setState({ progress });
handleUploadError = error => {
    this.setState({ isUploading: false });
    console.error(error);
  };

handleUploadSuccess = filename => {
    this.setState({ avatar: filename, progress: 100, isUploading: false });
    firebase
      .storage()
      .ref("images")
      .child(filename)
      .getDownloadURL()
      .then(url => this.setState({ avatarURL: url }));
};
    rechargeUser = () => {
        var userr = firebase.auth().currentUser;
        var database = firebase.database();

        console.log(userr.displayName);
        // userr.updateProfile({
        //     displayName: "Jane Q. User",
        //     photoURL: "https://example.com/jane-q-user/profile.jpg"
        //   }).then(function() {
        //       console.log('print')
        //     // Update successful.
        //   }).catch(function(error) {
        //     // An error happened.
        //     console.log('failed')
        //   });
        console.log(this.props.user.uid);

        console.log(this.state.name);
                if (this.state.name == ''){
                    console.log('name empty');
                }
                if (this.state.name != ''){
                    console.log('name not empty');
                    database.ref('users/'+ userr.uid).update({
                        displayName: this.state.name
                    });
                }
        console.log(this.state.phone);
                if (this.state.phone == ''){
                    console.log('phone empty');
                }
                if (this.state.phone != ''){
                    console.log('phone not empty');
                    database.ref('users/'+ userr.uid).update({
                        phone: this.state.phone
                    });
                }
        console.log(this.state.address);
                if (this.state.address == ''){
                    console.log('address empty');
                }
                if (this.state.address != ''){
                    console.log('address not empty');
                    database.ref('users/'+ userr.uid).update({
                        address: this.state.address
                    });
                }
        console.log(this.state.newpass);
        console.log(this.state.repeatpass);
                if (this.state.newpass == ''){
                    console.log('newpass empty');
                }
                if (this.state.newpass != ''){
                    console.log("this.state.newpass");
                    this.resetpassword();
                      }
                      if (this.state.city == ''){
                        console.log('city empty');
                    }
                    if (this.state.city != ''){
                        console.log('name not empty');
                        database.ref('users/'+ userr.uid).update({
                            city: this.state.city
                        });
                    }
                    if (this.state.state == ''){
                        console.log('state empty');
                    }
                    if (this.state.state != ''){
                        console.log('state not empty');
                        database.ref('users/'+ userr.uid).update({
                            state: this.state.state
                        });
                    }
                    if (this.state.zip == ''){
                        console.log('zip empty');
                    }
                    if (this.state.zip != ''){
                        console.log('zip not empty');
                        database.ref('users/'+ userr.uid).update({
                            zip: this.state.zip
                        });
                    }
                    if (this.state.country == ''){
                        console.log('country empty');
                    }
                    if (this.state.country != ''){
                        console.log('country not empty');
                        database.ref('users/'+ userr.uid).update({
                            country: this.state.country
                        });
                    }
                    if (this.state.avatarURL == ''){
                        console.log('avatar empty');
                    }
                    if (this.state.avatarURL != ''){
                        console.log('avatar not empty');
                        database.ref('users/'+ userr.uid).update({
                            photoURL: this.state.avatarURL
                        });
                    }
                if (this.state.isUploading == true){
                    alert("Please wait, image is uploading")
                    console.log("Please wait, image is uploading")
                }
                if (this.state.isUploading == false){
                alert("updated successfully")
                window.location.reload();
            }
    }
    render() {
        return (
            <div style = {styles.mediaObjects}>
                <form method="post" action="">

<h3>Update Account</h3>
{/* <label>Avatar:</label>
          {this.state.isUploading && <p>Progress: {this.state.progress}</p>}
          {this.state.avatarURL && <img src={this.state.avatarURL} />} */}

<label for="username">Name</label>
<input type="text"  name="name" value = {this.state.name} placeholder={this.props.user.displayName} autocomplete="off"  onChange = {this.handleChange}/>
<label for="username">Phone Number</label>
<input type="text" name="phone" value = {this.state.phone} placeholder= {this.props.user.phone} autocomplete="off"  onChange = {this.handleChange}/>
<label for="username">Address</label>
<input type="text" id="address" name="address" value = {this.state.address} placeholder={this.props.user.address} autocomplete="off"  onChange = {this.handleChange}/>
<label for="username">City</label>
<input type="text" id="city" name="city" value = {this.state.city} placeholder={this.props.user.city} autocomplete="off"  onChange = {this.handleChange}/>
<label for="username">State</label>
<input type="text" id="state" name="state" value = {this.state.state} placeholder={this.props.user.state} autocomplete="off"  onChange = {this.handleChange}/>
<label for="username">Zip</label>
<input type="text" id="zip" name="zip" value = {this.state.zip} placeholder={this.props.user.zip} autocomplete="off"  onChange = {this.handleChange}/>
<label for="username">Country</label>
<input type="text" id="country" name="country" value = {this.state.country} placeholder={this.props.user.country} autocomplete="off"  onChange = {this.handleChange}/>
<label for="photo">Upload Photo</label>
<FileUploader
            accept="image/*"
            name="avatar"
            randomizeFilename
            storageRef={firebase.storage().ref("images")}
            onUploadStart={this.handleUploadStart}
            onUploadError={this.handleUploadError}
            onUploadSuccess={this.handleUploadSuccess}
            onProgress={this.handleProgress}
          />
<input type="button" name="submit" value="Update" onClick = {this.rechargeUser}/>

<input type="button" name="submit" value="Reset Password" onClick = {this.resetpassword}/>

</form>
            </div>
        );  
    };
};

function mapStateToProps({ mediaReducer, socialSignIn }) {
    return {
        user: socialSignIn,
        media: mediaReducer.media
    };
}
export default connect(mapStateToProps, {updateUserBalance})(MyVideos);

const styles = {
    mediaObjects: {
        flex: 7,
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap'
    }  
};