import React, { Component } from "react";
import { connect } from "react-redux";
import { socialSignIn, authStateChanged, signUpWithEmailPassword, signInWithEmailPassword} from "../actions/social-signin-action";
import { getUsersList } from '../actions/game-zone-action';
import { getButtonState } from '../actions/media-action';
import './login-css.css';
import logo from '../assets/realclaw.png';
class Login extends Component {
  
  state = {
    open: false,
    open1: false
  };
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      password: '',
      chemail: '',
      signupemail: '',
      signuppass: '',
      signuppasscheck: ''
    }
  }
  handleInputChange = (event) => {
    this.setState({
      [event.target.name]: [event.target.value]
    }, function() {
    })
  }
  signUpUser = () => {
    console.log(this.state.signuppass)
    console.log(this.state.signuppasscheck)
    if (this.state.signuppass[0] === this.state.signuppasscheck[0]){
      // alert("password match")
    this.props.signUpWithEmailPassword(this.state.signupemail[0], this.state.signuppass[0])
    }
    if (this.state.signuppass[0] !== this.state.signuppasscheck[0]){
      alert("password didn't match")
    }
    
  }

  componentDidMount() {
    this.props.getButtonState()
    this.props.getUsersList()
  }

  socialSigninHandler = event => {
    const signInType = event.target.name;
    switch (signInType) {
      case "facebook":
        this.props.socialSignIn (signInType);
        break;
      case "google":
        this.props.socialSignIn (signInType);
        break;
      default:
        break;
    }
  };

  render() {
    return (
      
         <div className = "wrapper" >
  <div className="container">
  <a href="/">
  <img src= {logo}></img>
  </a>
  <form autoComplete="off">
          <h2 style = {{marginTop: 15}}>Create Account</h2>
          <label  for="resetemail">Email:</label>
          <input  type="text" class="testt"  style = {{marginTop: 15}} id="signupemail" name="signupemail" placeholder="Enter Email" style = {{border: 'solid 1px'}} onChange = {this.handleInputChange}/>
          <label  for="resetemail">Password:</label>
          <input  type="password" class="testt"  style = {{marginTop: 15}} id="signuppass" name="signuppass" placeholder="Password" style = {{border: 'solid 1px'}} onChange = {this.handleInputChange}/>
          <label  for="resetemail">Repeat Password:</label>
          <input  type="password" class="testt"  style = {{marginTop: 15}} id="signuppasscheck" name="signuppasscheck" placeholder="Repeat Password" style = {{border: 'solid 1px'}} onChange = {this.handleInputChange}/>
          <input type="button" style = {{marginTop: 15}} name = "Sign In" value = "Create Account" onClick = {this.signUpUser}/>
          </form>
  </div>
  </div>

  
    );
  }
};

export default connect (null, { socialSignIn, authStateChanged,getUsersList,getButtonState, signUpWithEmailPassword, signInWithEmailPassword})(Login);
