import {addUserToGameZone, removeUserFromList} from '../firebase/firebase-gamezone'
import firebase from "firebase";
const fbdatabase = firebase.database();
const queueRef = fbdatabase.ref('queue');
export const USER_LOGIN_SUCCESSFUL = 'USER_LOGIN_SUCCESSFUL'
export const ADDING_USER_TO_GAME_ZONE = 'ADDING_USER_TO_GAME_ZONE'
export const USER_ADDED_TO_GAME_ZONE = 'USER_ADDED_TO_GAME_ZONE'
export const ERROR_ADDING_USER = 'ERROR_ADDING_USER'
export const FETCHING_USER_LIST = 'FETCHING_USER_LIST'
export const USERS_FETCHED = 'USERS_FETCHED'
export const ERROR_FETCHING_USERS = 'ERROR_FETCHING_USERS'

export function addUserToGame (user) {
    return (dispatch) => {
        dispatch({
            type: ADDING_USER_TO_GAME_ZONE,
            payload: null
        })
        return addUserToGameZone(user).then(() => {
            dispatch({
                type: USER_ADDED_TO_GAME_ZONE,
                payload: null
            })
        }).catch(err => {
            dispatch({
                type: ERROR_ADDING_USER,
                payload: null
            })
        })
    }
}

export function updateUserBalance(userId, balance) {
    const userRef = fbdatabase.ref(`users/${userId}`);
    return (dispatch) => {
        return userRef.update({balance: balance}).then((user) => {
            userRef.once("value", function(_user) {
                if(_user.val()) {
                    dispatch({
                        type: USER_LOGIN_SUCCESSFUL,
                        payload: _user.val()
                    })
                }
                
            })
        })
        
    }

}

export function updateUserEntryInGameRoom(userId) {
    const userRef = fbdatabase.ref(`queue/${userId}`);
    return (dispatch) => {
        userRef.once("value", function(queue) {
            if (queue.val() && !queue.val().gamePlay) {
                userRef.update({gamePlay: true, gameStartTime: firebase.database.ServerValue.TIMESTAMP})
            }
        })
        
    }
    
}

export function removeUser(userId) {
    return (dispatch) => {
        dispatch({
            type: 'USER_REMOVED',
            payload: null
        })
        removeUserFromList(userId)
    }
}

export async function getUsersList () {
    
    return async (dispatch) => {
        dispatch({
            type: FETCHING_USER_LIST,
            payload: null
        });
        queueRef.orderByChild('createdAt')
        .on('value', function(snapshot) {
        let data = [];
      
        snapshot.forEach(function(child) {
          data.push(child.val());
        });
        dispatch({
            type: USERS_FETCHED,
            payload: data
        })
    })
        
    }
}

export async function getUsersList1 () {
    
    return async (dispatch) => {
        dispatch({
            type: FETCHING_USER_LIST,
            payload: null
        });
        queueRef.orderByChild('createdAt')
        .on('value', function(snapshot) {
        let data = [];
      
        snapshot.forEach(function(child) {
          data.push(child.val());
        });
        data.shift();
        dispatch({
            type: USERS_FETCHED,
            payload: data
        })
    })
        
    }
}